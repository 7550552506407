import { IVariant, IVariantDCOV } from "../../interfaces";
import { nonTablePriceProvider } from "../../providers/nonTableProvider";
import { currencyFormatter } from "../../utils";
import { getPrice, getPriceHeader } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getNonTable = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.nonTable.filter((g) => g.price > 0);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    { text: "Beztabulkové položky", style: "h4" },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: ["auto", "auto", "auto", "auto", "*", 150],
        body: [
          [
            { text: "Popis", style: "tableHeader" },
            { text: "Cena", style: "tableHeader" },
            { text: "Životnost max.", style: "tableHeader" },
            { text: "Životnost min.", style: "tableHeader" },
            ...getPriceHeader(),
          ],
          ...filtered.map((g) => [
            getOstr(g.text),
            `${currencyFormatter(g.price)} ${g.withVAT ? "bez DPH" : "s DPH"}`,
            `${g.min} let`,
            `${g.max} let`,
            ...getPrice(g, nonTablePriceProvider),
          ]),
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getNonTable;
