import { TextField, FormControl, Grid } from "@mui/material";
import Price from "../components/Price";
import { IPriceQuantity } from "../interfaces";
import {
  sanaceReconstructionOptionProvider,
  sanaceReconstructionProvider,
} from "../providers/sanaceReconstructionProvider";
import { currencyFormatter } from "../utils";

interface IProps {
  state: IPriceQuantity;
  setState: (state: any) => void;
}

export default function ItemSanaceReconstruction(props: IProps) {
  const { min, max } = sanaceReconstructionOptionProvider();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={5}>
        <FormControl fullWidth>
          <TextField
            label={`Cena (ks) ${currencyFormatter(min)} - ${currencyFormatter(
              max
            )} (bez DPH)`}
            size="small"
            value={props.state.price}
            onChange={(e) =>
              props.setState({ ...props.state, price: e.target.value })
            }
            type="number"
            inputProps={{ min, max }}
            error={props.state.price < min || props.state.price > max}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            label="Počet"
            size="small"
            value={props.state.quantity}
            onChange={(e) =>
              props.setState({ ...props.state, quantity: e.target.value })
            }
            type="number"
            inputProps={{ min: 0 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <Price data={props.state} provider={sanaceReconstructionProvider} />
      </Grid>
    </Grid>
  );
}
