import { FormControlLabel, TextField, Grid, Checkbox } from "@mui/material";
import Price from "../components/Price";
import { INonTable } from "../interfaces";
import { nonTablePriceProvider } from "../providers/nonTableProvider";

interface IProps {
  state: INonTable;
  setState: (data: any) => void;
}

export default function ItemNonTable({ state, setState }: IProps) {
  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs={5} style={{ height: "100%" }}>
        <TextField
          multiline
          rows={3}
          fullWidth
          size="small"
          label="Popis"
          InputProps={{
            style: {
              height: 96,
            },
          }}
          value={state.text}
          onChange={(e) => setState({ ...state, text: e.target.value })}
        />
      </Grid>
      <Grid item xs={5} container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Cena"
            value={state.price}
            onChange={(e) => setState({ ...state, price: e.target.value })}
            type="number"
            inputProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.withVAT}
                onChange={(e) =>
                  setState({
                    ...state,
                    withVAT: e.target.checked,
                  })
                }
                size="small"
              />
            }
            label="Cena je s DPH"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Min. životnost (v letech)"
            value={state.min}
            onChange={(e) => setState({ ...state, min: e.target.value })}
            type="number"
            inputProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Max. životnost (v letech)"
            value={state.max}
            onChange={(e) => setState({ ...state, max: e.target.value })}
            type="number"
            inputProps={{ min: 0 }}
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Price data={state} provider={nonTablePriceProvider} />
      </Grid>
    </Grid>
  );
}
