import { configureStore } from "@reduxjs/toolkit";
import vatReducer from "./slices/vatSlice";

export interface IStore {
  vat: boolean;
}

export default configureStore({
  reducer: {
    vat: vatReducer,
  },
});
