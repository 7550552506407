import { pointsFormatterPDF } from "../../components/Points";
import { IVariant, IVariantDCOV } from "../../interfaces";
import { variantEconomicPointProvider } from "../../providers/variantProvider";
import { getSectionMargin, getTocItem } from "../utils";

const getEconomic = (
  allVariants: (IVariant | IVariantDCOV)[],
  variant: IVariant | IVariantDCOV
) => {
  const investment = variantEconomicPointProvider(
    allVariants,
    variant,
    "investment"
  );
  const min = variantEconomicPointProvider(allVariants, variant, "min");
  const max = variantEconomicPointProvider(allVariants, variant, "max");

  return [
    {
      text: "Vyhodnocení ekonomické nákladnosti",
      style: "h3",
      ...getTocItem(),
    },
    {
      layout: "lightHorizontalLines",
      table: {
        body: [
          [
            { text: "Náklady investiční", style: "tableHeader" },
            {
              text: "Náklady po dobu provozování min. (za 100 let provozu)",
              style: "tableHeader",
            },
            {
              text: "Náklady po dobu provozování max. (za 100 let provozu)",
              style: "tableHeader",
            },
          ],
          [
            pointsFormatterPDF(investment),
            pointsFormatterPDF(min),
            pointsFormatterPDF(max),
          ],
        ],
      },
      ...getSectionMargin(),
    },
  ];
};

export default getEconomic;
