import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import PhotoIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, Dialog, IconButton } from "@mui/material";

interface IProps {
  images: string[];
  setImages: (images: string[]) => void;
}

export default function ImageDropzone(props: IProps) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const contents = event.target!.result;
        props.setImages([...props.images, contents as string]);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    [props]
  );
  const [modalOpen, setModalOpen] = useState<number | null>(null);

  const onRemove = (index: number) => {
    props.setImages(props.images.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <>
      <Dialog
        open={modalOpen !== null}
        onClose={() => setModalOpen(null)}
        maxWidth="xl"
      >
        <img
          onClick={() => setModalOpen(null)}
          src={props.images?.[modalOpen!]}
          style={{
            maxWidth: "100%",
            cursor: "zoom-out",
            display: "block",
          }}
          alt="Ilustrační obrázek"
        />
      </Dialog>

      <Grid container spacing={2}>
        {props.images.map((image, index) => (
          <Grid item key={index}>
            <div style={{ position: "relative" }}>
              <img
                onClick={() => setModalOpen(index)}
                src={image}
                style={{
                  maxHeight: "200px",
                  cursor: "zoom-in",
                  display: "block",
                }}
                alt="Ilustrační obrázek"
              />
              <IconButton
                onClick={() => onRemove(index)}
                style={{
                  background: "white",
                  color: "black",
                  position: "absolute",
                  right: "8px",
                  top: "8px",
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Grid>
        ))}
      </Grid>

      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <div>
            <Button startIcon={<PhotoIcon />} color={"primary"}>
              Nahrát ilustrační obrázek
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
