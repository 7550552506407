import { ISemaphore } from "../interfaces";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

interface IProps {
  label: string;
  value: ISemaphore;
  onChange: (value: ISemaphore) => void;
  disableOrange?: boolean;
}

export default function Semaphore(props: IProps) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel>{props.label}</InputLabel>
      <Select
        label={props.label}
        size="small"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value as any)}
      >
        <MenuItem value="">Neurčeno</MenuItem>
        <MenuItem value="red">🔴 Červená</MenuItem>
        {!props.disableOrange && (
          <MenuItem value="orange">🟠 Oranžová</MenuItem>
        )}
        <MenuItem value="green">🟢 Zelená</MenuItem>
      </Select>
    </FormControl>
  );
}
