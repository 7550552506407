import { IPriceQuantity } from "../interfaces";
import mmr_3_11 from "../data/mmr/3.11.json";
import { calculateCosts, includeVat } from "../utils";

export function sanaceReconstructionBigProvider(
  data: IPriceQuantity,
  method: string
) {
  const genuine = "mmr" === method;
  return calculateCosts(
    includeVat(data.price * data.quantity, !mmr_3_11.vat_included),
    mmr_3_11.lifespan,
    genuine
  );
}

export function sanaceReconstructionBigOptionProvider() {
  return mmr_3_11.data.reconstruction_big;
}

export const sanaceReconstructionBigDefaultState = {
  price:
    (mmr_3_11.data.reconstruction_big.min +
      mmr_3_11.data.reconstruction_big.max) /
    2,
  quantity: 0,
};
