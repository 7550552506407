import { saveAs } from "file-saver";
import { IVariant, IVariantDCOV } from "../interfaces";
import generatePdf from "./generatePdf";

// Create ZIP with all output files and save it
const downloadReport = async (variants: IVariant[], dcov: IVariantDCOV) => {
  //const zip = new JSZip();

  // Generate all charts
  // await Promise.all(
  //   variants.map((variant) =>
  //     renderChart(variant, dcov).then((blob) => {
  //       zip.file(`${variant.name}.png`, blob);
  //     })
  //   )
  // );

  // Generate PDF
  await generatePdf(variants, dcov).then((blob) => {
    //zip.file("report.pdf", blob);
    saveAs(blob, "report.pdf");
  });

  // Download the zip
  // zip.generateAsync({ type: "blob" }).then((content) => {
  //   saveAs(content, "report.zip");
  // });
};

export default downloadReport;
