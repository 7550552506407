import { ITypeQuantity } from "../interfaces";
import mmr_3_11 from "../data/mmr/3.11.json";
import { calculateCosts, includeVat } from "../utils";

export function sanaceProvider(data: ITypeQuantity, method: string) {
  const genuine = "mmr" === method;
  const cost = (mmr_3_11.data.profile as any)[data.type];
  const money = cost * data.quantity;
  return calculateCosts(
    includeVat(money, !mmr_3_11.vat_included),
    mmr_3_11.lifespan,
    genuine
  );
}

export function sanaceOptionProvider() {
  return Object.keys(mmr_3_11.data.profile);
}

export const sanaceDefaultState = {
  type: "200",
  quantity: 0,
};
