import {
  FormControlLabel,
  TextField,
  Grid,
  Checkbox,
  Divider,
} from "@mui/material";
import ImageDropzone from "../components/ImageDropzone";
import Points from "../components/Points";
import Semaphore from "../components/Semaphore";
import { ILand } from "../interfaces";
import { landPointProvider } from "../providers/landProvider";

interface IProps {
  state: ILand;
  setState: (data: ILand) => void;
}

export default function ItemLand({ state, setState }: IProps) {
  return (
    <>
      <ImageDropzone
        images={state.images}
        setImages={(images) => setState({ ...state, images })}
      />
      <Divider style={{ marginTop: 5, marginBottom: 15 }} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <TextField
            label="Název katastru"
            size="small"
            fullWidth
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Parcelní číslo"
            size="small"
            fullWidth
            value={state.number}
            onChange={(e) => setState({ ...state, number: e.target.value })}
          />
        </Grid>
        <Grid item xs={2}>
          <Semaphore
            label="Vstupní semafor"
            value={state.inSemaphore}
            onChange={(value) =>
              setState({
                ...state,
                inSemaphore: value,
              })
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Semaphore
            label="Výstupní semafor"
            value={state.outSemaphore}
            onChange={(value) =>
              setState({
                ...state,
                outSemaphore: value,
              })
            }
            disableOrange
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.keyParcel}
                onChange={(e) =>
                  setState({ ...state, keyParcel: e.target.checked })
                }
              />
            }
            label="Klíčový pozemek"
          />
        </Grid>
        <Grid item xs={1} textAlign="right">
          <Points data={state} provider={landPointProvider} />
        </Grid>
        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            fullWidth
            size="small"
            label="Zápis projektanta"
            value={state.plannerText}
            onChange={(e) =>
              setState({ ...state, plannerText: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            multiline
            rows={3}
            fullWidth
            size="small"
            label="Zápis starosty"
            value={state.mayorText}
            onChange={(e) => setState({ ...state, mayorText: e.target.value })}
          />
        </Grid>
      </Grid>
    </>
  );
}
