import { TextField, Grid } from "@mui/material";
import { detailedGeoPriceProvider } from "../providers/detailedGeoProvider";
import Price from "../components/Price";
import { ITextQuantity } from "../interfaces";

interface IProps {
  setState: (state: any) => void;
  state: ITextQuantity;
}

export default function ItemDetailedGeo(props: IProps) {
  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2, mt: 1 }}>
      <Grid item>
        <TextField
          label="Počet"
          size="small"
          value={props.state.quantity}
          onChange={(e) =>
            props.setState({
              ...props.state,
              quantity: e.target.value,
            })
          }
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          label="Vysvětlivka"
          size="small"
          fullWidth
          value={props.state.text}
          onChange={(e) =>
            props.setState({
              ...props.state,
              text: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item>
        <Price data={props.state} provider={detailedGeoPriceProvider} />
      </Grid>
    </Grid>
  );
}
