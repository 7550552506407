import React from "react";
import { ICalculation } from "../interfaces";
import {
  Typography,
  Grid,
  Box,
  Popover,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { currencyFormatter } from "../utils";

interface IProps {
  data: any;
  provider: (data: any, method: string) => ICalculation;
}

const getPrice = (
  calculation: ICalculation,
  property: "investment" | "min" | "max"
) => {
  if (calculation.genuine) {
    return currencyFormatter(calculation[property]);
  }

  return (
    <Typography textAlign="center" fontSize={14}>
      —
    </Typography>
  );
};

export default function Price(props: IProps) {
  const costMMR = props.provider(props.data, "mmr");
  const costMZe = props.provider(props.data, "mze");

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
      >
        <Grid
          item
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Box mr={1}>
            <Typography align="right" fontSize={14}>
              MMR:
            </Typography>
            <Typography align="right" fontSize={14}>
              MZe:
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Typography align="right" fontSize={14}>
            {getPrice(costMMR, "investment")}
          </Typography>
          <Typography align="right" fontSize={14}>
            {getPrice(costMZe, "investment")}
          </Typography>
        </Grid>
      </Grid>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        <Box>
          {!costMMR.genuine && (
            <Typography sx={{ p: 2 }} fontWeight="bold">
              Není v publikaci MMR ÚÚR řešeno - pro celkový výpočet jsou použity
              data z metodického pokynu MZe
            </Typography>
          )}
          {!costMZe.genuine && (
            <Typography sx={{ p: 2 }} fontWeight="bold">
              Není v metodickém pokynu MZe řešeno - pro celkový výpočet jsou
              použity data z publikace MMR ÚÚR
            </Typography>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Metodika</TableCell>
                <TableCell>Náklady investiční</TableCell>
                <TableCell>
                  Náklady po dobu provozování min. (za 100 let provozu)
                </TableCell>
                <TableCell>
                  Náklady po dobu provozování max. (za 100 let provozu)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {costMMR.genuine && (
                <TableRow>
                  <TableCell>MMR</TableCell>
                  <TableCell>{getPrice(costMMR, "investment")}</TableCell>
                  <TableCell>{getPrice(costMMR, "min")}</TableCell>
                  <TableCell>{getPrice(costMMR, "max")}</TableCell>
                </TableRow>
              )}
              {costMZe.genuine && (
                <TableRow>
                  <TableCell>MZe</TableCell>
                  <TableCell>{getPrice(costMZe, "investment")}</TableCell>
                  <TableCell>{getPrice(costMZe, "min")}</TableCell>
                  <TableCell>{getPrice(costMZe, "max")}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Popover>
    </>
  );
}
