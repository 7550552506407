import { Typography } from "@mui/material";

interface IProps {
  data: any;
  provider: (data: any) => number;
}

export const pointsFormatter = (count: number) => `${count} 🔹`;

export const pointsFormatterPDF = (count: number) => `${count} b.`;

export default function Points({ data, provider }: IProps) {
  const count = provider(data);

  return <Typography>{pointsFormatter(count)}</Typography>;
}
