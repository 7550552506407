import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { pointsFormatter } from "../components/Points";
import { IVariant, IVariantDCOV } from "../interfaces";
import { variantEconomicPointProvider } from "../providers/variantProvider";

interface IProps {
  variant: IVariant | IVariantDCOV;
  variants: (IVariant | IVariantDCOV)[];
}

export default function ItemEconomic({ variant, variants }: IProps) {
  const investment = variantEconomicPointProvider(
    variants,
    variant,
    "investment"
  );
  const min = variantEconomicPointProvider(variants, variant, "min");
  const max = variantEconomicPointProvider(variants, variant, "max");

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Náklady investiční</TableCell>
            <TableCell>
              Náklady po dobu provozování min. (za 100 let provozu)
            </TableCell>
            <TableCell>
              Náklady po dobu provozování max. (za 100 let provozu)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{pointsFormatter(investment)}</TableCell>
            <TableCell>{pointsFormatter(min)}</TableCell>
            <TableCell>{pointsFormatter(max)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
