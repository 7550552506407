import { ISaveState } from "./interfaces";
import { nonTableDefaultState } from "./providers/nonTableProvider";
import { sanaceConnectionDefaultState } from "./providers/sanaceConnectionProvider";
import { sanaceDefaultState } from "./providers/sanaceProvider";
import { sanaceReconstructionBigDefaultState } from "./providers/sanaceReconstructionBigProvider";
import { sanaceReconstructionDefaultState } from "./providers/sanaceReconstructionProvider";

function omit(key: string, obj: { [key: string]: any }) {
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

const migrateSaveState = (state: any): ISaveState => {
  if (state.version === 1) {
    return v2tov3(v1tov2(state));
  }
  if (state.version === 2) {
    return v2tov3(state);
  }

  return state;
};

const v1tov2 = (state: any) => ({
  ...state,
  dcov: {
    ...omit("image", state.dcov),
    sanace: [sanaceDefaultState],
    sanaceConnection: [sanaceConnectionDefaultState],
    sanaceReconstruction: [sanaceReconstructionDefaultState],
    sanaceReconstructionBig: [sanaceReconstructionBigDefaultState],
    sanaceRoad: 0,
    images: typeof state.dcov.image === "string" ? [state.dcov.image] : [],
    land: state.dcov.land.map((land: any) => ({
      ...omit("image", land),
      images: typeof land.image === "string" ? [land.image] : [],
    })),
  },
  variants: state.variants.map((variant: any) => ({
    ...omit("image", variant),
    sanace: [sanaceDefaultState],
    sanaceConnection: [sanaceConnectionDefaultState],
    sanaceReconstruction: [sanaceReconstructionDefaultState],
    sanaceReconstructionBig: [sanaceReconstructionBigDefaultState],
    sanaceRoad: 0,
    images: typeof variant.image === "string" ? [variant.image] : [],
    land: variant.land.map((land: any) => ({
      ...omit("image", land),
      images: typeof land.image === "string" ? [land.image] : [],
    })),
  })),
  version: 2,
});

const v2tov3 = (state: any) => {
  if (typeof state?.dcov?.nonTable !== "undefined") {
    return {
      ...state,
      version: 3,
    };
  }

  return {
    ...state,
    dcov: {
      ...state.dcov,
      nonTable: [nonTableDefaultState],
      detailedGeo: {
        text: "",
        quantity: state.dcov.detailedGeo,
      },
      indicativeGeo: {
        text: "",
        quantity: state.dcov.indicativeGeo,
      },
    },
    variants: state.variants.map((variant: any) => ({
      ...variant,
      nonTable: [nonTableDefaultState],
      detailedGeo: {
        text: "",
        quantity: state.dcov.detailedGeo,
      },
      indicativeGeo: {
        text: "",
        quantity: state.dcov.indicativeGeo,
      },
      pressuredStation: [
        {
          text: "",
          quantity: variant.pressuredStation,
        },
      ],
    })),
    version: 3,
  };
};

export default migrateSaveState;
