import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { pointsFormatter } from "../components/Points";
import Section from "../components/Section";
import { IVariant, IVariantDCOV } from "../interfaces";
import {
  variantEconomicPointProvider,
  variantLandPointProvider,
  variantPointProvider,
  variantPublicGoodPointProvider,
} from "../providers/variantProvider";

interface IProps {
  allVariants: (IVariant | IVariantDCOV)[];
}

export default function Summary({ allVariants }: IProps) {
  return (
    <Section>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Varianta</TableCell>
              <TableCell>
                Ekonomická nákladnost
                <br />
                (investice)
              </TableCell>
              <TableCell>
                Ekonomická nákladnost
                <br />
                (náklady min)
              </TableCell>
              <TableCell>
                Ekonomická nákladnost
                <br />
                (náklady max)
              </TableCell>
              <TableCell>Majetkoprávní vyhodnocení</TableCell>
              <TableCell>Vyhodnocení veřejné prospěšnosti stavby</TableCell>
              <TableCell>Celkové vyhodnocení</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allVariants.map((variant, index) => (
              <TableRow key={index}>
                <TableCell>
                  {"name" in variant ? variant.name : "DČOV"}
                </TableCell>
                <TableCell>
                  {pointsFormatter(
                    variantEconomicPointProvider(
                      allVariants,
                      variant,
                      "investment"
                    )
                  )}
                </TableCell>
                <TableCell>
                  {pointsFormatter(
                    variantEconomicPointProvider(allVariants, variant, "min")
                  )}
                </TableCell>
                <TableCell>
                  {pointsFormatter(
                    variantEconomicPointProvider(allVariants, variant, "max")
                  )}
                </TableCell>
                <TableCell>
                  {pointsFormatter(
                    variantLandPointProvider(allVariants, variant)
                  )}
                </TableCell>
                <TableCell>
                  {pointsFormatter(variantPublicGoodPointProvider(variant))}
                </TableCell>
                <TableCell>
                  {pointsFormatter(variantPointProvider(allVariants, variant))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
}
