import { TextField, Grid, FormControl } from "@mui/material";
import { storageTankPriceProvider } from "../providers/storageTankProvider";
import Price from "../components/Price";
import { ISizeQuantity } from "../interfaces";

interface IProps {
  setState: (state: any) => void;
  state: ISizeQuantity;
}

export default function ItemStorageTank(props: IProps) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={5}>
        <FormControl fullWidth>
          <TextField
            label="Objem (m3)"
            size="small"
            value={props.state.size}
            onChange={(e) =>
              props.setState({ ...props.state, size: e.target.value })
            }
            type="number"
            inputProps={{ min: 0 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            label="Počet"
            size="small"
            value={props.state.quantity}
            onChange={(e) =>
              props.setState({ ...props.state, quantity: e.target.value })
            }
            type="number"
            inputProps={{ min: 0 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <Price data={props.state} provider={storageTankPriceProvider} />
      </Grid>
    </Grid>
  );
}
