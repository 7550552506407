import { IVariant, IVariantDCOV } from "../interfaces";
import renderChart from "./renderChart";
import calculate from "../Calculate";
import { currencyFormatter } from "../utils";
import getPressuredStation from "./items/pressuredStation";
import getGravitational from "./items/gravitational";
import getGravitationalConnection from "./items/gravitationalConnection";
import getPressured from "./items/pressured";
import getCov from "./items/cov";
import getStorageTank from "./items/storageTank";
import getSeepage from "./items/seepage";
import getIndicativeGeo from "./items/indicativeGeo";
import getDetailedGeo from "./items/detailedGeo";
import getPublicGood from "./items/publicGood";
import { getSectionMargin, getTocItem, IMAGE_WIDTH } from "./utils";
import getLand from "./items/land";
import getEconomic from "./items/economic";
import getSanace from "./items/sanace";
import getSanaceConnection from "./items/sanaceConnection";
import getSanaceReconstruction from "./items/sanaceReconstruction";
import getSanaceReconstructionBig from "./items/sanaceReconstructionBig";
import getSanaceRoad from "./items/sanaceRoad";
import getNonTable from "./items/nonTable";

const getItems = (variant: IVariant) => {
  return [
    ...getGravitational(variant),
    ...getGravitationalConnection(variant),
    ...getPressured(variant),
    ...getPressuredStation(variant),
    ...getCov(variant),
    ...getStorageTank(variant),
    ...getSeepage(variant),
    ...getIndicativeGeo(variant),
    ...getDetailedGeo(variant),
    ...getSanace(variant),
    ...getSanaceConnection(variant),
    ...getSanaceReconstruction(variant),
    ...getSanaceReconstructionBig(variant),
    ...getSanaceRoad(variant),
    ...getNonTable(variant),
  ];
};

// Get all variants (excluding DCOV)
const getVariants = async (dcov: IVariantDCOV, variants: IVariant[]) => {
  return Promise.all(
    variants.map(async (v) => {
      const content = [];

      // Variant header (name)
      content.push({
        text: `Varianta ${v.name}`,
        style: "h2",
        pageBreak: "before",
        margin: [0, 0, 0, 10],
        tocItem: true,
      });

      // Variant image
      if (v.images.length > 0) {
        content.push({
          text: "Ilustrační obrázky",
          style: "h4",
        });
        content.push(
          v.images.map((image) => ({
            image,
            width: IMAGE_WIDTH,
            ...getSectionMargin(),
          }))
        );
      }

      // Items
      const items = getItems(v);
      if (items.length > 0) {
        content.push({ text: "Položky", style: "h3", ...getTocItem() });
        content.push(...items);
      }

      // Land
      content.push(...getLand(v));

      // Public good
      content.push(...getPublicGood(v));

      // Costs
      content.push({
        stack: [
          { text: "Náklady", style: "h3", ...getTocItem() },
          {
            image: await renderChart(v, dcov),
            width: IMAGE_WIDTH,
          },
        ],
        unbreakable: true,
      });

      // Costs table
      const costs = calculate(v, dcov);
      content.push({
        layout: "lightHorizontalLines",
        table: {
          body: [
            // Header
            [
              { text: "Varianta", style: "tableHeader" },
              { text: "Náklady investiční", style: "tableHeader" },
              {
                text: "Náklady po dobu provozování min. (za 100 let provozu)",
                style: "tableHeader",
              },
              {
                text: "Náklady po dobu provozování max. (za 100 let provozu)",
                style: "tableHeader",
              },
            ],
            // Data
            ...costs.map((row) => [
              row.name,
              currencyFormatter(row.investment),
              currencyFormatter(row.min),
              currencyFormatter(row.max),
            ]),
          ],
        },
        unbreakable: true,
        ...getSectionMargin(),
      });

      content.push(...getEconomic([dcov, ...variants], v));

      return content;
    })
  );
};

export default getVariants;
