import ReactDOM from "react-dom";
import { IVariant, IVariantDCOV } from "../interfaces";
import Chart from "../components/Chart";
import { toPng } from "html-to-image";
import calculate from "../Calculate";

// Render React Recharts chart to png as string url
const renderChart = (
  variant: IVariant,
  dcov: IVariantDCOV
): Promise<string> => {
  // Chart dimensions
  const [width, height] = [1500, 600];
  const labelHeight = 50;

  return new Promise((resolve) => {
    const data = calculate(variant, dcov);

    // Setup elements
    const el = document.createElement("div");
    const chart = (
      <Chart
        data={data}
        forRender
        width={width}
        height={height - labelHeight}
      />
    );

    // Render the chart into div outside the main DOM
    ReactDOM.render(chart, el, () => {
      // Obtain legend element
      const legendElements = el.getElementsByClassName(
        "recharts-legend-wrapper"
      ) as HTMLCollectionOf<HTMLElement>;

      if (legendElements.length === 0) {
        throw new Error("No legend element found!");
      }

      // Fix legend position
      legendElements[0].style.bottom = `${-labelHeight}px`;

      // Convert HTML to PNG and resolve the promise
      toPng(el, {
        width,
        height,
      }).then((data) => {
        if (data === null) {
          throw new Error("Image generation failed!");
        }

        resolve(data);
      });
    });
  });
};

export default renderChart;
