import { useTheme } from "@mui/material/styles";
import { Divider } from "@mui/material";

interface IProps {
  orientation?: "horizontal" | "vertical";
  flexItem?: boolean;
  style?: React.CSSProperties;
}

export default function ItemDivider({ style, ...props }: IProps) {
  const theme = useTheme();

  return (
    <Divider
      style={{
        ...style,
        marginTop: 15,
        marginBottom: 20,
        backgroundColor: theme.palette.common.layout.itemDivider,
      }}
      {...props}
    />
  );
}
