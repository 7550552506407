import { Switch } from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

interface IProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export default function ThemeSwitch(props: IProps) {
  return (
    <Switch
      icon={<LightModeIcon fontSize="small" />}
      checkedIcon={<DarkModeIcon fontSize="small" />}
      onChange={(e) => props.onChange(e.target.checked)}
      checked={props.checked}
    />
  );
}
