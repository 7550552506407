import { TextField, Grid } from "@mui/material";
import Price from "../components/Price";
import { sanaceRoadProvider } from "../providers/sanaceRoadProvider";

interface IProps {
  setState: (state: any) => void;
  state: any;
}

export default function ItemSanaceRoad(props: IProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2, mt: 1 }}
        >
          <Grid item xs={2}>
            <TextField
              label="Plocha (m2)"
              size="small"
              value={props.state}
              onChange={(e) => props.setState(e.target.value)}
              type="number"
              inputProps={{ min: 0 }}
              sx={{ pr: 1 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={10} sx={{ pr: "50px" }}>
            <Price data={props.state} provider={sanaceRoadProvider} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div style={{ width: "52px" }} />
      </Grid>
    </Grid>
  );
}
