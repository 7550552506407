import { calculateDCOV, calculateVariant } from "../Calculate";
import { IVariant, IVariantDCOV } from "../interfaces";
import { landPointProvider } from "./landProvider";
import { publicGoodPointProvider } from "./publicGoodProvider";

export type ICriterion = "investment" | "min" | "max";

export function variantEconomicPointProvider(
  variants: (IVariant | IVariantDCOV)[],
  variant: IVariant | IVariantDCOV,
  criterion: ICriterion
): number {
  // Calculate cost for each variant by criterion
  const variantsPoints = variants
    .map((v) =>
      "name" in v
        ? calculateVariant(v, "mmr")[criterion]
        : calculateDCOV(v, "mmr")[criterion]
    )
    // Sort variants by criterion (high to low)
    .sort((a, b) => b - a);

  // Calculate cost for supplied variant
  const variantPoints =
    "name" in variant
      ? calculateVariant(variant, "mmr")[criterion]
      : calculateDCOV(variant, "mmr")[criterion];

  // Find rank of supplied variant
  const variantIndex = variantsPoints.indexOf(variantPoints);

  if (variantIndex === -1) {
    throw new Error("Variant not found in variants (first argument)");
  }

  // Return point value of supplied variant
  return variantIndex + 1;
}

export function variantLandPointProvider(
  variants: (IVariant | IVariantDCOV)[],
  variant: IVariant | IVariantDCOV
): number {
  // Calculate land points for each variant, sort, deduplicate
  const variantsPoints = Array.from(
    new Set(
      variants.map((v) =>
        v.land.reduce((acc, item) => acc + landPointProvider(item), 0)
      )
    )
  ).sort((a, b) => a - b);

  // Calculate land points for supplied variant
  const variantPoints = variant.land.reduce(
    (acc, item) => acc + landPointProvider(item),
    0
  );

  // Find rank of supplied variant
  const variantIndex = variantsPoints.indexOf(variantPoints);

  if (variantIndex === -1) {
    throw new Error("Variant not found in variants (first argument)");
  }

  // Return point value of supplied variant
  return variantIndex + 1;
}

export function variantPublicGoodPointProvider(
  variant: IVariant | IVariantDCOV
): number {
  return publicGoodPointProvider(variant.publicGood);
}

export function variantPointProvider(
  variants: (IVariant | IVariantDCOV)[],
  variant: IVariant | IVariantDCOV
): number {
  return (
    variantEconomicPointProvider(variants, variant, "investment") +
    variantEconomicPointProvider(variants, variant, "min") +
    variantEconomicPointProvider(variants, variant, "max") +
    variantLandPointProvider(variants, variant) +
    variantPublicGoodPointProvider(variant)
  );
}
