import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";

import {
  gravitationalConnectionOptionProvider,
  gravitationalConnectionPriceProvider,
} from "../providers/gravitationalConnectionProvider";
import { ISewerageConnection } from "../interfaces";
import Price from "../components/Price";

interface IProps {
  state: ISewerageConnection;
  setState: (state: any) => void;
}

export default function ItemGravitationalConnection({
  state,
  setState,
}: IProps) {
  const { types, profiles } = gravitationalConnectionOptionProvider(state);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel>Materiál</InputLabel>
          <Select
            label="Materiál"
            size="small"
            value={state.type}
            onChange={(e) => setState({ ...state, type: e.target.value })}
          >
            {types.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Profil</InputLabel>
          <Select
            label="Profil"
            size="small"
            value={state.profile}
            onChange={(e) => {
              setState({ ...state, profile: e.target.value });
            }}
          >
            {profiles.map((profile) => (
              <MenuItem key={profile} value={profile}>
                {profile}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            label="Délka"
            value={state.quantity}
            onChange={(e) => setState({ ...state, quantity: e.target.value })}
            type="number"
            size="small"
            inputProps={{ min: 0 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <Price data={state} provider={gravitationalConnectionPriceProvider} />
      </Grid>
    </Grid>
  );
}
