import mmr_pressuredStation from "../data/mmr/pressuredStation.json";
import mze_15_7 from "../data/mze/15-7.json";
import { IQuantity } from "../interfaces";
import { includeVat, calculateCosts, sumCalc } from "../utils";

export function pressuredStationPriceProvider(data: IQuantity, method: string) {
  if (method === "mmr") {
    const buildingMoney = includeVat(
      data.quantity * mmr_pressuredStation.building.price,
      !mmr_pressuredStation.vat_included
    );
    const technologyMoney = includeVat(
      data.quantity * mmr_pressuredStation.technology.price,
      !mmr_pressuredStation.vat_included
    );
    return sumCalc([
      calculateCosts(
        buildingMoney,
        mmr_pressuredStation.building.lifespan,
        true
      ),
      calculateCosts(
        technologyMoney,
        mmr_pressuredStation.technology.lifespan,
        true
      ),
    ]);
  }

  if (method === "mze") {
    const money = includeVat(
      data.quantity * mze_15_7.price,
      !mze_15_7.vat_included
    );
    return calculateCosts(money, mze_15_7.lifespan, true);
  }

  throw new Error("Method not implemented");
}

export const pressuredStationDefaultState = {
  quantity: 0,
};
