import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
} from "@mui/material";
import { useState, useEffect } from "react";
import {
  ISewerageConnection,
  ISizeQuantity,
  IVariantDCOV,
} from "../interfaces";
import { gravitationalConnectionDefaultState } from "../providers/gravitationalConnectionProvider";
import { storageTankDefaultState } from "../providers/storageTankProvider";

interface IProps {
  open: boolean;
  onClose: () => void;
  data: IVariantDCOV;
  setData: (data: IVariantDCOV) => void;
}

interface IListItem {
  [key: string]: boolean;
}

export default function MagicDialog({ open, onClose, data, setData }: IProps) {
  const [connections, setConnections] = useState<IListItem>({});
  const [tanks, setTanks] = useState<IListItem>({});

  const handleWand = () => {
    const newGc: ISewerageConnection[] = [];
    const newTank: ISizeQuantity[] = [];

    // Sort and filter connections and tanks
    data.cov
      .filter(
        (item) => typeof item.name === "string" && item.name.trim().length > 0
      )
      .forEach((item) => {
        const name = item?.name?.trim()!;
        const gc = data.gravitationalConnection.find(
          (gc) => typeof gc.name === "string" && gc.name.trim() === name
        );
        const tank = data.storageTank.find(
          (tank) => typeof tank.name === "string" && tank.name.trim() === name
        );

        if (gc) {
          newGc.push(gc);
        } else {
          newGc.push({ ...gravitationalConnectionDefaultState, name });
        }

        if (tank) {
          newTank.push(tank);
        } else {
          newTank.push({ ...storageTankDefaultState, name });
        }
      });

    // Copy all unchecked connections and tanks
    Object.entries(connections).forEach(([key, value]) => {
      if (!value) {
        newGc.push(
          data.gravitationalConnection.find(
            (gc) => typeof gc.name === "string" && gc.name.trim() === key
          )!
        );
      }
    });
    Object.entries(tanks).forEach(([key, value]) => {
      if (!value) {
        newTank.push(
          data.storageTank.find(
            (tank) => typeof tank.name === "string" && tank.name.trim() === key
          )!
        );
      }
    });

    // Copy all connections and tanks with no name
    data.gravitationalConnection
      .filter(
        (item) =>
          !(typeof item.name === "string" && item.name.trim().length > 0)
      )
      .forEach((item) => newGc.push(item));
    data.storageTank
      .filter(
        (item) =>
          !(typeof item.name === "string" && item.name.trim().length > 0)
      )
      .forEach((item) => newTank.push(item));

    setData({
      ...data,
      gravitationalConnection: newGc,
      storageTank: newTank,
    });
    onClose();
  };

  // Updates the list of connections and tanks
  useEffect(() => {
    if (open) {
      const newConnections: IListItem = {};
      const newTanks: IListItem = {};

      data.gravitationalConnection
        .filter(
          (item) => typeof item.name === "string" && item.name.trim().length > 0
        )
        .forEach((gc) => {
          const name = gc?.name?.trim()!;
          const exists = data.cov.some(
            (item) => typeof item.name === "string" && item.name.trim() === name
          );
          if (!exists) {
            newConnections[name] = true;
          }
        });

      data.storageTank
        .filter(
          (item) => typeof item.name === "string" && item.name.trim().length > 0
        )
        .forEach((gc) => {
          const name = gc?.name?.trim()!;
          const exists = data.cov.some(
            (item) => typeof item.name === "string" && item.name.trim() === name
          );
          if (!exists) {
            newTanks[name] = true;
          }
        });

      setConnections(newConnections);
      setTanks(newTanks);
    }
  }, [data, open]);

  if (!open) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Automatické generování přípojek a nádrží</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Z položek ČOV se automaticky vygenerují položky přípojek a nádrží.
          Proběhne seřazení položek podle pořadí v ČOV. Budou smazány všechny
          položky přípojek a nádrží, které nejsou v ČOV (lze vybrat níže).
        </DialogContentText>

        {Object.keys(connections).length > 0 && (
          <>
            <Typography variant="subtitle2">
              Přípojky, které budou smazány
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    setConnections(
                      Object.fromEntries(
                        Object.entries(connections).map(([key, _]) => [
                          key,
                          true,
                        ])
                      )
                    )
                  }
                >
                  Označit vše
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    setConnections(
                      Object.fromEntries(
                        Object.entries(connections).map(([key, _]) => [
                          key,
                          false,
                        ])
                      )
                    )
                  }
                >
                  Odznačit vše
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        <List>
          {Object.entries(connections).map(([key, value]) => (
            <ListItem disablePadding key={key}>
              <ListItemButton
                role={undefined}
                onClick={() =>
                  setConnections({
                    ...connections,
                    [key]: !value,
                  })
                }
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={value}
                    tabIndex={-1}
                    disableRipple
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText primary={key} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {Object.keys(tanks).length > 0 && (
          <>
            <Typography variant="subtitle2">
              Nádrže, které budou smazány
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    setTanks(
                      Object.fromEntries(
                        Object.entries(tanks).map(([key, _]) => [key, true])
                      )
                    )
                  }
                >
                  Označit vše
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    setTanks(
                      Object.fromEntries(
                        Object.entries(tanks).map(([key, _]) => [key, false])
                      )
                    )
                  }
                >
                  Odznačit vše
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        <List>
          {Object.entries(tanks).map(([key, value]) => (
            <ListItem disablePadding key={key}>
              <ListItemButton
                role={undefined}
                onClick={() =>
                  setTanks({
                    ...tanks,
                    [key]: !value,
                  })
                }
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={value}
                    tabIndex={-1}
                    disableRipple
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText primary={key} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Zrušit</Button>
        <Button onClick={() => handleWand()}>Pokračovat</Button>
      </DialogActions>
    </Dialog>
  );
}
