import mmr3_1 from "../data/mmr/3.1.json";
import mmr3_2 from "../data/mmr/3.2.json";
import mze13_9 from "../data/mze/13-9.json";
import { ISewerage, ICalculation } from "../interfaces";
import { includeVat, calculateCosts } from "../utils";

export function gravitationalPriceProvider(
  data: ISewerage,
  method: string
): ICalculation {
  if (method === "mze") {
    const file = mze13_9;
    const typeString = mapToMze(data.type);
    const type = file.data[typeString];
    const profiles = data.consolidated ? type.z : type.n;
    const money = includeVat(
      (profiles as any)[data.profile] * data.quantity,
      !file.vat_included
    );
    return calculateCosts(money, file.lifespan, true);
  }

  if (method === "mmr") {
    const file = data.consolidated ? mmr3_2 : mmr3_1;
    const money = includeVat(
      (file.data as any)[data.type][data.profile] * data.quantity,
      !file.vat_included
    );
    return calculateCosts(money, file.lifespan, true);
  }

  throw new Error("Method not implemented");
}

export function gravitationalOptionProvider(state: ISewerage) {
  const types = Object.keys(mmr3_1.data);
  const profiles = Object.entries((mmr3_1.data as any)[state.type])
    .filter((entry) => entry[1] != null)
    .map((entry) => entry[0])
    // Only include sensible/useful profiles
    // All profiles defined in file 3.1.json, 3.2.json, 13-9.json
    .filter((key) => ["250", "300", "400", "500"].includes(key));

  return { types, profiles };
}

export const gravitationalDefaultState = {
  type: "plastové",
  profile: "250",
  consolidated: false,
  quantity: 0,
};

// MMR -> MZe material mapping
const mapToMze = (type: string) => {
  switch (type) {
    case "plastové":
      return "PVC, PE";
    case "litinové":
      return "litina";
    case "sklolaminátové":
      return "železobeton, sklolaminát";
    case "betonové":
      return "železobeton, sklolaminát";
    case "železobetonové":
      return "železobeton, sklolaminát";
    case "kameninové obetonované":
      return "kamenina";
  }

  throw new Error("Unknown type: " + type);
};
