import { createSlice } from "@reduxjs/toolkit";

export const vatSlice = createSlice({
  name: "vat",
  initialState: true,
  reducers: {
    set: (_, action) => action.payload,
  },
});

export const { set } = vatSlice.actions;

export default vatSlice.reducer;
