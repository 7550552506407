import { IVariant, IVariantDCOV } from "../../interfaces";
import { indicativeGeoPriceProvider } from "../../providers/indicativeGeoProvider";
import { getPrice, getWidths, getPriceHeader } from "../price";
import { getItemMargin } from "../utils";

const getIndicativeGeo = (variant: IVariant | IVariantDCOV) => {
  // Skip
  if (variant.indicativeGeo.quantity === 0) {
    return [];
  }

  return [
    {
      stack: [
        {
          text: "Orientační hydrogeologický průzkum (pro plošnou zálivku)",
          style: "h4",
        },
        {
          layout: "lightHorizontalLines",
          table: {
            widths: getWidths(2),
            body: [
              [
                { text: "Počet", style: "tableHeader" },
                { text: "Vysvětlivka", style: "tableHeader" },
                ...getPriceHeader(),
              ],
              [
                variant.indicativeGeo.quantity.toString(),
                variant.indicativeGeo.text,
                ...getPrice(variant.indicativeGeo, indicativeGeoPriceProvider),
              ],
            ],
          },
        },
      ],
      unbreakable: true,
      ...getItemMargin(),
    },
  ];
};

export default getIndicativeGeo;
