import { ICalculation, ILifespan } from "./interfaces";
import store from "./store";

// Get money in the correct format
export const currencyFormatter = (value: number) =>
  Intl.NumberFormat("cs-CZ", {
    style: "currency",
    currency: "CZK",
    maximumFractionDigits: 0,
  }).format(value);

// Sum multiple calculations
export const sumCalc = (calculations: ICalculation[]) =>
  calculations.reduce(
    (acc: ICalculation, value) => ({
      investment: acc.investment + value.investment,
      min: acc.min + value.min,
      max: acc.max + value.max,
      genuine: acc.genuine && value.genuine,
    }),
    { investment: 0, min: 0, max: 0, genuine: true }
  );

// Round calculation up
export const roundCalc = (calculation: ICalculation) => ({
  investment: Math.ceil(calculation.investment),
  min: Math.ceil(calculation.min),
  max: Math.ceil(calculation.max),
});

// Include VAT if include is true
export const includeVat = (money: number, include: boolean) => {
  const vatEnabled = store.getState().vat;

  if (vatEnabled) {
    return include ? money * 1.21 : money;
  } else {
    return include ? money : money / 1.21;
  }
};

// Calculate costs from lifespan
export const calculateCosts = (
  money: number,
  lifespan: ILifespan,
  genuine: boolean
) => ({
  investment: money,
  min: (100 / lifespan.max) * money,
  max: (100 / lifespan.min) * money,
  genuine,
});
