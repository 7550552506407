import {
  FormControl,
  TextField,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ItemGravitational from "../items/ItemGravitational";
import ItemGravitationalConnection from "../items/ItemGravitationalConnection";
import ItemSeepageObject from "../items/ItemSeepageObject";
import ItemPressured from "../items/ItemPressured";
import ItemCOV from "../items/ItemCOV";
import { IVariant, IVariantDCOV } from "../interfaces";
import { useState } from "react";
import ItemStack from "../items/ItemStack";
import Section from "../components/Section";
import ImageDropzone from "../components/ImageDropzone";
import { gravitationalDefaultState } from "../providers/gravitationalProvider";
import { pressuredDefaultState } from "../providers/pressuredProvider";
import { gravitationalConnectionDefaultState } from "../providers/gravitationalConnectionProvider";
import { seepageDefaultState } from "../providers/seepageProvider";
import { covDefaultState } from "../providers/covProvider";
import ItemIndicativeGeo from "../items/ItemIndicativeGeo";
import ItemDetailedGeo from "../items/itemDetailedGeo";
import ItemPressuredStation from "../items/ItemPressuredStation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ItemDivider from "../components/ItemDivider";
import ItemStorageTank from "../items/ItemStorageTank";
import { storageTankDefaultState } from "../providers/storageTankProvider";
import ItemLand from "../items/ItemLand";
import ItemPublicGood from "../items/ItemPublicGood";
import { landDefaultState } from "../providers/landProvider";
import ItemEconomic from "../items/ItemEconomic";
import CostsResults from "../components/CostsResults";
import ItemSanace from "../items/ItemSanace";
import { sanaceDefaultState } from "../providers/sanaceProvider";
import ItemSanaceConnection from "../items/ItemSanaceConnection";
import { sanaceConnectionDefaultState } from "../providers/sanaceConnectionProvider";
import ItemSanaceReconstruction from "../items/ItemSanaceReconstruction";
import { sanaceReconstructionDefaultState } from "../providers/sanaceReconstructionProvider";
import ItemSanaceReconstructionBig from "../items/ItemSanaceReconstructionBig";
import { sanaceReconstructionBigDefaultState } from "../providers/sanaceReconstructionBigProvider";
import ItemSanaceRoad from "../items/ItemSanaceRoad";
import ItemNonTable from "../items/ItemNonTable";
import { nonTableDefaultState } from "../providers/nonTableProvider";
import { pressuredStationDefaultState } from "../providers/pressuredStationProvider";

interface IProps {
  data: IVariant;
  dcov: IVariantDCOV;
  allVariants: (IVariant | IVariantDCOV)[];
  setName: (name: string) => void;
  updateState: (data: any, itemName: string) => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onSend: (item: any, key: string) => void;
}

export default function BaseVariant({
  data,
  setName,
  updateState,
  onDelete,
  onDuplicate,
  dcov,
  allVariants,
  onSend,
}: IProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Box>
      <Section bottomPadding>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControl>
              <TextField
                value={data.name}
                label="Jméno varianty"
                size="small"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => setDialogOpen(true)}
              >
                Smazat variantu
              </Button>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <Button
                variant="outlined"
                startIcon={<ContentCopyIcon />}
                onClick={() => onDuplicate()}
              >
                Duplikovat variantu
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Section>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">Smazat variantu?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Opravdu chcete variantu smazat? Všechny data z této varianty budou
            ztraceny.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
              onDelete();
            }}
          >
            Potvrdit
          </Button>
          <Button autoFocus onClick={() => setDialogOpen(false)}>
            Zrušit
          </Button>
        </DialogActions>
      </Dialog>
      <Section>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2">Ilustrační obrázky</Typography>
        </Box>
        <ImageDropzone
          images={data.images}
          setImages={(images) => updateState(images, "images")}
        />
      </Section>
      <Card sx={{ mb: 2 }}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={data.centralExpanded}
          onChange={(_, expanded) => updateState(expanded, "centralExpanded")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Centrální prvky</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle2">Kanalizace gravitační</Typography>
            <ItemStack
              Component={ItemGravitational}
              state={data.gravitational}
              update={(data) => updateState(data, "gravitational")}
              newItemData={gravitationalDefaultState}
              sendButton
              onSend={(data) => onSend(data, "gravitational")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Kanalizace - gravitační přípojky
            </Typography>
            <ItemStack
              Component={ItemGravitationalConnection}
              state={data.gravitationalConnection}
              update={(data) => updateState(data, "gravitationalConnection")}
              newItemData={gravitationalConnectionDefaultState}
              sendButton
              onSend={(data) => onSend(data, "gravitationalConnection")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">Kanalizace tlaková</Typography>
            <ItemStack
              Component={ItemPressured}
              state={data.pressured}
              update={(data) => updateState(data, "pressured")}
              newItemData={pressuredDefaultState}
              sendButton
              onSend={(data) => onSend(data, "pressured")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Kanalizace tlaková - čerpací stanice u RD
            </Typography>
            <ItemStack
              Component={ItemPressuredStation}
              state={data.pressuredStation}
              update={(data) => updateState(data, "pressuredStation")}
              newItemData={pressuredStationDefaultState}
              sendButton
              onSend={(data) => onSend(data, "pressuredStation")}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
      <Card sx={{ mb: 2 }}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={data.decentralExpanded}
          onChange={(_, expanded) => updateState(expanded, "decentralExpanded")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Decentrální prvky</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle2">ČOV domovní</Typography>
            <ItemStack
              Component={ItemCOV}
              state={data.cov}
              update={(newData) => {
                updateState(newData, "cov");
              }}
              newItemData={covDefaultState}
              sendButton
              onSend={(data) => onSend(data, "cov")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">Akumulační nádrž</Typography>
            <ItemStack
              Component={ItemStorageTank}
              state={data.storageTank}
              update={(newData) => {
                updateState(newData, "storageTank");
              }}
              newItemData={storageTankDefaultState}
              sendButton
              onSend={(data) => onSend(data, "storageTank")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Bodový vsakovací objekt za ČOV
            </Typography>
            <ItemStack
              Component={ItemSeepageObject}
              state={data.seepage}
              update={(newData) => {
                updateState(newData, "seepage");
              }}
              newItemData={seepageDefaultState}
              sendButton
              onSend={(data) => onSend(data, "seepage")}
            />
            <ItemDivider />
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs>
                <Typography variant="subtitle2">
                  Orientační hydrogeologický průzkum (pro plošnou zálivku)
                </Typography>
                <ItemIndicativeGeo
                  state={data.indicativeGeo}
                  setState={(newData) => {
                    updateState(newData, "indicativeGeo");
                  }}
                />
              </Grid>
              <ItemDivider
                flexItem
                orientation="vertical"
                style={{ marginLeft: 16 }}
              />
              <Grid item xs>
                <Typography variant="subtitle2">
                  Podrobný hydrogeologický průzkum (pro vsakovací objekt)
                </Typography>
                <ItemDetailedGeo
                  state={data.detailedGeo}
                  setState={(newData) => {
                    updateState(newData, "detailedGeo");
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
      <Card sx={{ mb: 2 }}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={data.reconstructionExpanded}
          onChange={(_, expanded) =>
            updateState(expanded, "reconstructionExpanded")
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Rekonstrukce kanalizace</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle2">Rekonstrukce kanalizace</Typography>
            <ItemStack
              Component={ItemSanace}
              state={data.sanace}
              update={(data) => updateState(data, "sanace")}
              newItemData={sanaceDefaultState}
              sendButton
              onSend={(data) => onSend(data, "sanace")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Rekonstrukce kanalizace - napojení domovních přípojek
            </Typography>
            <ItemStack
              Component={ItemSanaceConnection}
              state={data.sanaceConnection}
              update={(data) => updateState(data, "sanaceConnection")}
              newItemData={sanaceConnectionDefaultState}
              sendButton
              onSend={(data) => onSend(data, "sanaceConnection")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Rekonstrukce kanalizace - rekonstrukce kanalizačních šachet
            </Typography>
            <ItemStack
              Component={ItemSanaceReconstruction}
              state={data.sanaceReconstruction}
              update={(data) => updateState(data, "sanaceReconstruction")}
              newItemData={sanaceReconstructionDefaultState}
              sendButton
              onSend={(data) => onSend(data, "sanaceReconstruction")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Rekonstrukce kanalizace - bourání a následná obnova stropu šachet
              u profilu DN 800 a vyšších
            </Typography>
            <ItemStack
              Component={ItemSanaceReconstructionBig}
              state={data.sanaceReconstructionBig}
              update={(data) => updateState(data, "sanaceReconstructionBig")}
              newItemData={sanaceReconstructionBigDefaultState}
              sendButton
              onSend={(data) => onSend(data, "sanaceReconstructionBig")}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Rekonstrukce kanalizace - oprava asfaltové komunikace, poškozené
              při rekonstrukci kanalizace
            </Typography>
            <ItemSanaceRoad
              state={data.sanaceRoad}
              setState={(value) => updateState(value, "sanaceRoad")}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
      <Card>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={data.nonTableExpanded}
          onChange={(_, expanded) => updateState(expanded, "nonTableExpanded")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Beztabulkové položky</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ItemStack
              Component={ItemNonTable}
              state={data.nonTable}
              update={(newData) => {
                updateState(newData, "nonTable");
              }}
              newItemData={nonTableDefaultState}
              noName
              sendButton
              onSend={(data) => onSend(data, "nonTable")}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
      <Section>
        <CostsResults variant={data} dcov={dcov} />
      </Section>
      <Section>
        <Typography variant="subtitle2">
          Vyhodnocení ekonomické nákladnosti
        </Typography>
        <ItemEconomic variant={data} variants={allVariants} />
      </Section>
      <Section>
        <Typography variant="subtitle2">Majetkoprávní vyhodnocení</Typography>
        <ItemStack
          Component={ItemLand}
          state={data.land}
          update={(newData) => {
            updateState(newData, "land");
          }}
          newItemData={landDefaultState}
          noName
          thickDivider
          sendButton
          onSend={(data) => onSend(data, "land")}
        />
      </Section>
      <Section bottomPadding>
        <Typography variant="subtitle2">
          Vyhodnocení veřejné prospěšnosti stavby
        </Typography>
        <ItemPublicGood
          data={data.publicGood}
          setData={(newData) => updateState(newData, "publicGood")}
        />
      </Section>
    </Box>
  );
}
