import mze15_11 from "../data/mze/15-11.json";
import { ISewerage } from "../interfaces";
import { includeVat, calculateCosts } from "../utils";

export function pressuredPriceProvider(data: ISewerage, method: string) {
  const genuine = "mze" === method;
  const type = (mze15_11.data as any)[data.type];
  const profiles = data.consolidated ? type.z : type.n;
  const money = includeVat(
    (profiles as any)[data.profile] * data.quantity,
    !mze15_11.vat_included
  );
  return calculateCosts(money, mze15_11.lifespan, genuine);
}

export function pressuredOptionProvider(state: ISewerage) {
  const types = Object.keys(mze15_11.data);
  const profiles = Object.keys((mze15_11.data as any)[state.type].n);

  return { types, profiles };
}

export const pressuredDefaultState = {
  type: "litina",
  profile: "80",
  consolidated: false,
  quantity: 0,
};
