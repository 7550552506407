import { IVariant } from "../interfaces";

// Get table of contents
const getTableOfContents = (variants: IVariant[]) => [
  {
    toc: {
      title: { text: "Obsah", style: "h2" },
    },
  },
];

export default getTableOfContents;
