import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, useReducer } from "react";
import {
  Container,
  Button,
  Tab,
  Tabs,
  Box,
  Grid,
  Divider,
  CssBaseline,
  Stack,
} from "@mui/material";
import ProjectDropzone from "./components/ProjectDropzone";
import { saveAs } from "file-saver";
import BaseVariant from "./variants/BaseVariant";
import AddIcon from "@mui/icons-material/Add";
import { produce } from "immer";
import DCOVVariant from "./variants/DCOVVariant";
import DownloadIcon from "@mui/icons-material/Download";

import { ISaveState, IVariant, IVariantDCOV } from "./interfaces";
import VATSwitch from "./components/VATSwitch";
import dcovVariantDefaultState from "./variants/dcovVariantDefaultState";
import baseVariantDefaultState from "./variants/baseVariantDefaultState";
import ThemeSwitch from "./components/ThemeSwitch";
import { useBeforeunload } from "react-beforeunload";
import { grey } from "@mui/material/colors";
import Summary from "./variants/Summary";
import downloadReport from "./report/reportGenerator";
import Header from "./Header";
import migrateSaveState from "./migrate";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import TabPanel, { getTabProps } from "./components/TabPanel";
import SendDialog from "./components/SendDialog";

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    layout: {
      itemDivider: string;
    };
  }
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    common: {
      layout: {
        itemDivider: grey[800],
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "rgb(234, 238, 243)",
    },
    common: {
      layout: {
        itemDivider: grey[400],
      },
    },
  },
});

const JSON_VERSION = 2;

function App() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isDarkTheme, setDarkTheme] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [sendItem, setSendItem] = useState<any>(null);
  const theme = isDarkTheme ? darkTheme : lightTheme;
  const [state, setState] = useState({
    version: JSON_VERSION,
    variants: [
      {
        ...baseVariantDefaultState,
        name: "Centrál",
        decentralExpanded: false,
      },
      { ...baseVariantDefaultState, name: "Ostrovní" },
      { ...baseVariantDefaultState, name: "Hybrid" },
    ],
    dcov: dcovVariantDefaultState,
    selectedVariant: 0,
    unsavedChanges: false,
  } as ISaveState);

  const allVariants: (IVariant | IVariantDCOV)[] = [
    state.dcov,
    ...state.variants,
  ];

  useBeforeunload((event) => {
    if (state.unsavedChanges) {
      event.preventDefault();
    }
  });

  const download = () => {
    saveAs(
      new Blob([
        JSON.stringify({
          ...state,
          unsavedChanges: false,
        }),
      ]),
      "kanal.json"
    );
    setState({
      ...state,
      unsavedChanges: false,
    });
  };

  const load = (data: object) => {
    setState(migrateSaveState(data));
  };

  const reorderTabs = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    setState(
      produce(state, (draft) => {
        const [removed] = draft.variants.splice(result.source.index, 1);
        draft.variants.splice(result.destination?.index!, 0, removed);
      })
    );
  };

  console.log(state.selectedVariant);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl">
        <Header />
        <Box sx={{ p: 2 }}>
          <ProjectDropzone
            onDrop={load}
            unsavedChanges={state.unsavedChanges}
          />
        </Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "sticky",
            top: 0,
            backgroundColor: theme.palette.background.default,
            opacity: 1,
            zIndex: 10,
          }}
        >
          <Grid
            container
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Grid item>
              <DragDropContext onDragEnd={reorderTabs}>
                <Droppable droppableId="tabs" direction="horizontal">
                  {(props) => (
                    <div ref={props.innerRef} {...props.droppableProps}>
                      <Tabs
                        value={state.selectedVariant}
                        onChange={(_: any, newValue: any) => {
                          setState({
                            ...state,
                            selectedVariant: newValue,
                          });
                        }}
                      >
                        <Tab label="DČOV" {...getTabProps(0)} />
                        <Divider
                          flexItem
                          orientation="vertical"
                          sx={{ mx: 2, my: 1 }}
                        />
                        {state.variants.map((tab, index) => (
                          <Draggable
                            key={index}
                            draggableId={`id-${index}`}
                            index={index}
                            disableInteractiveElementBlocking
                            {...{ value: index + 1 }}
                          >
                            {(props) => (
                              <div
                                ref={props.innerRef}
                                {...props.draggableProps}
                              >
                                <Tab
                                  {...props.dragHandleProps}
                                  label={tab.name}
                                  style={{ cursor: "inherit" }}
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      selectedVariant: index + 1,
                                    })
                                  }
                                  {...getTabProps(index + 2)}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {props.placeholder}
                        <Box mx={2} my={1}>
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() =>
                              setState(
                                produce((draft) => {
                                  // Push new tab
                                  draft.variants.push(baseVariantDefaultState);
                                  // Set new tab as selected
                                  draft.selectedVariant = draft.variants.length;
                                  draft.unsavedChanges = true;
                                })
                              )
                            }
                          >
                            Nová varianta
                          </Button>
                        </Box>
                        <Tab
                          label="Vyhodnocení"
                          {...getTabProps(state.variants.length + 1)}
                        />
                      </Tabs>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
            <Grid item>
              <VATSwitch onUpdate={forceUpdate} />
              <ThemeSwitch onChange={setDarkTheme} checked={isDarkTheme} />
            </Grid>
          </Grid>
        </Box>
        <TabPanel index={0} value={state.selectedVariant}>
          <DCOVVariant
            allVariants={allVariants}
            data={state.dcov}
            setData={(data) => {
              setState({
                ...state,
                dcov: data,
                unsavedChanges: true,
              });
            }}
            onDuplicate={() => {
              setState(
                produce((draft) => {
                  draft.variants.push({
                    ...baseVariantDefaultState,
                    ...state.dcov,
                    name: "DČOV (kopie)",
                  });
                })
              );
            }}
            onSend={(item, key) => {
              setSendItem({ item, key });
              setSendDialogOpen(true);
            }}
          />
        </TabPanel>
        <TabPanel
          index={state.variants.length + 1}
          value={state.selectedVariant}
        >
          <Summary allVariants={allVariants} />
        </TabPanel>
        {state.variants.map((tab, index) => (
          <TabPanel index={index + 1} value={state.selectedVariant} key={index}>
            <BaseVariant
              allVariants={allVariants}
              data={tab}
              dcov={state.dcov}
              setName={(name) =>
                setState(
                  produce((draft) => {
                    draft.variants[index].name = name;
                    draft.unsavedChanges = true;
                  })
                )
              }
              updateState={(data, itemName) =>
                setState(
                  produce((draft) => {
                    (draft.variants[index] as any)[itemName] = data;
                    draft.unsavedChanges = true;
                  })
                )
              }
              onDelete={() =>
                setState(
                  produce((draft) => {
                    draft.variants.splice(index, 1);
                    draft.selectedVariant = 0;
                    draft.unsavedChanges = true;
                  })
                )
              }
              onDuplicate={() =>
                setState(
                  produce((draft) => {
                    const newVariant = {
                      ...draft.variants[index],
                      name: `${draft.variants[index].name} (kopie)`,
                    };
                    draft.variants.push(newVariant);
                  })
                )
              }
              onSend={(item, key) => {
                setSendItem({ item, key });
                setSendDialogOpen(true);
              }}
            />
          </TabPanel>
        ))}
        <Box sx={{ textAlign: "center", paddingBottom: 5 }}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              onClick={download}
              startIcon={<DownloadIcon />}
            >
              Stáhnout projekt
            </Button>
            <Button
              variant="contained"
              onClick={() => downloadReport(state.variants, state.dcov)}
              startIcon={<DownloadIcon />}
            >
              Stáhnout výstupní zprávu
            </Button>
          </Stack>
        </Box>
      </Container>
      <SendDialog
        onClose={() => setSendDialogOpen(false)}
        onSend={(variant) => {
          setSendDialogOpen(false);
          setState(
            produce((draft) => {
              if (variant === 0) {
                (draft.dcov as any)[sendItem.key].push(sendItem.item);
              } else {
                (draft.variants as any)[variant - 1][sendItem.key].push(
                  sendItem.item
                );
              }
            })
          );
        }}
        open={sendDialogOpen}
        variants={allVariants}
        itemKey={sendItem?.key}
      />
    </ThemeProvider>
  );
}

export default App;
