// Get title and preface
const getPreface = () => [
  {
    text: "Výstupní zpráva",
    style: "h1",
    margin: [0, 0, 0, 40],
  },
];

export default getPreface;
