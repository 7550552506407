import { Box, Card } from "@mui/material";

interface IProps {
  children: React.ReactNode;
  bottomPadding?: boolean;
  noTopPadding?: boolean;
  noMargin?: boolean;
}

export default function Section({
  children,
  bottomPadding,
  noTopPadding,
  noMargin,
}: IProps) {
  return (
    <Box my={noMargin ? 0 : 2}>
      <Card>
        <Box sx={{ p: 2, pb: bottomPadding ? 2 : 0, pt: noTopPadding ? 0 : 2 }}>
          {children}
        </Box>
      </Card>
    </Box>
  );
}
