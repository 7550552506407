import { IPriceQuantity } from "../interfaces";
import mmr_3_11 from "../data/mmr/3.11.json";
import { calculateCosts, includeVat } from "../utils";

export function sanaceConnectionProvider(data: IPriceQuantity, method: string) {
  const genuine = "mmr" === method;
  return calculateCosts(
    includeVat(data.price * data.quantity, !mmr_3_11.vat_included),
    mmr_3_11.lifespan,
    genuine
  );
}

export function sanaceConnectionOptionProvider() {
  return mmr_3_11.data.connection;
}

export const sanaceConnectionDefaultState = {
  price: (mmr_3_11.data.connection.min + mmr_3_11.data.connection.max) / 2,
  quantity: 0,
};
