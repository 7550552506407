import { TextField, FormControlLabel, Checkbox, Grid } from "@mui/material";
import Points from "../components/Points";
import { IPublicGood } from "../interfaces";
import { publicGoodPointProvider } from "../providers/publicGoodProvider";

interface IProps {
  data: IPublicGood;
  setData: (data: IPublicGood) => void;
}

export default function ItemPublicGood({ data, setData }: IProps) {
  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.checked}
              onChange={(e) => setData({ ...data, checked: e.target.checked })}
            />
          }
          label="Veřejně prospěšná stavba"
        />
      </Grid>
      <Grid item>
        <Points provider={publicGoodPointProvider} data={data} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          rows={8}
          fullWidth
          size="small"
          value={data.text}
          onChange={(e) =>
            setData({
              ...data,
              text: e.target.value,
            })
          }
        />
      </Grid>
    </Grid>
  );
}
