// Get text when param is possibly undefined
export const getOstr = (text?: string) => {
  return typeof text === "undefined" ? "" : text;
};

export const getItemMargin = () => ({
  margin: [0, 0, 0, 20],
});

export const getSectionMargin = () => ({
  margin: [0, 0, 0, 20],
});

export const getTocItem = () => ({
  tocItem: true,
  tocMargin: [20, 0, 0, 0],
});

export const IMAGE_WIDTH = 515;
