import publicGoodData from "../data/points/publicGood.json";
import { IPublicGood } from "../interfaces";

export function publicGoodPointProvider(data: IPublicGood): number {
  return data.checked ? publicGoodData.points : 0;
}

export const publicGoodDefaultState: IPublicGood = {
  text: "",
  checked: false,
};
