import { IVariant, IVariantDCOV } from "../../interfaces";
import { gravitationalConnectionPriceProvider } from "../../providers/gravitationalConnectionProvider";
import { getPrice, getWidths, getPriceHeader } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getGravitationalConnection = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.gravitationalConnection.filter(
    (g) => g.quantity > 0
  );

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    { text: "Kanalizace - gravitační přípojky", style: "h4" },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: getWidths(4),
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Materiál", style: "tableHeader" },
            { text: "Profil", style: "tableHeader" },
            { text: "Délka", style: "tableHeader" },
            ...getPriceHeader(),
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            g.type,
            g.profile,
            g.quantity,
            ...getPrice(g, gravitationalConnectionPriceProvider),
          ]),
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getGravitationalConnection;
