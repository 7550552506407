import { IVariant, IVariantDCOV } from "../../interfaces";
import { getSectionMargin, getTocItem } from "../utils";

const getPublicGood = (variant: IVariant | IVariantDCOV) => {
  if (variant.publicGood.checked) {
    return [
      {
        text: "Vyhodnocení veřejné prospěšnosti stavby",
        style: "h3",
        ...getTocItem(),
      },
      {
        text: variant.publicGood.text,
        ...getSectionMargin(),
      },
    ];
  }

  return [];
};

export default getPublicGood;
