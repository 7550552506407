import { IVariant, IVariantDCOV } from "../../interfaces";
import { sanaceRoadProvider } from "../../providers/sanaceRoadProvider";
import { getPrice, getWidths, getPriceHeader } from "../price";
import { getItemMargin } from "../utils";

const getSanaceRoad = (variant: IVariant | IVariantDCOV) => {
  // Skip
  if (variant.sanaceRoad === 0) {
    return [];
  }

  return [
    {
      stack: [
        {
          text: "Rekonstrukce kanalizace - oprava asfaltové komunikace, poškozené při rekonstrukci kanalizace",
          style: "h4",
        },
        {
          layout: "lightHorizontalLines",
          table: {
            widths: getWidths(1),
            body: [
              [{ text: "Počet", style: "tableHeader" }, ...getPriceHeader()],
              [
                variant.sanaceRoad.toString(),
                ...getPrice(variant.sanaceRoad, sanaceRoadProvider),
              ],
            ],
          },
        },
      ],
      unbreakable: true,
      ...getItemMargin(),
    },
  ];
};

export default getSanaceRoad;
