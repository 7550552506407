import detailedGeo from "../data/mmr/detailedGeo.json";
import { ITextQuantity } from "../interfaces";
import { includeVat } from "../utils";

export function detailedGeoPriceProvider(data: ITextQuantity, method: string) {
  const genuine = method === "mmr";
  const money = includeVat(
    detailedGeo.price * data.quantity,
    !detailedGeo.vat_included
  );
  return { investment: money, min: 0, max: 0, genuine };
}

export const detailedGeoDefaultState: ITextQuantity = {
  text: "",
  quantity: 0,
};
