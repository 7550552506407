import indicativeGeo from "../data/mmr/indicativeGeo.json";
import { ITextQuantity } from "../interfaces";
import { includeVat } from "../utils";

export function indicativeGeoPriceProvider(
  data: ITextQuantity,
  method: string
) {
  const genuine = method === "mmr";
  const money = includeVat(
    indicativeGeo.price * data.quantity,
    !indicativeGeo.vat_included
  );
  return { investment: money, min: 0, max: 0, genuine };
}

export const indicativeGeoDefaultState: ITextQuantity = {
  text: "",
  quantity: 0,
};
