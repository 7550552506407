import { IVariant, IVariantDCOV } from "../interfaces";
import {
  variantEconomicPointProvider,
  variantLandPointProvider,
  variantPointProvider,
  variantPublicGoodPointProvider,
} from "../providers/variantProvider";
import { pointsFormatterPDF } from "../components/Points";

// Get final summary - with points
const getSummary = (dcov: IVariantDCOV, variants: IVariant[]) => {
  const allVariants = [...variants, dcov];

  return [
    {
      text: "Celkové vyhodnocení",
      style: "h2",
      pageBreak: "before",
      id: "summary",
      tocItem: true,
    },
    {
      layout: "lightHorizontalLines",
      table: {
        body: [
          // Header
          [
            { text: "Varianta", style: "tableHeader", fontSize: 10 },
            {
              text: "Ekonomická nákladnost (investice)",
              style: "tableHeader",
              fontSize: 10,
            },
            {
              text: "Ekonomická nákladnost (náklady min)",
              style: "tableHeader",
              fontSize: 10,
            },
            {
              text: "Ekonomická nákladnost (náklady max)",
              style: "tableHeader",
              fontSize: 10,
            },
            {
              text: "Majetkoprávní vyhodnocení",
              style: "tableHeader",
              fontSize: 10,
            },
            {
              text: "Vyhodnocení veřejné prospěšnosti stavby",
              style: "tableHeader",
              fontSize: 10,
            },
            { text: "Celkové vyhodnocení", style: "tableHeader", fontSize: 10 },
          ],
          // Data
          ...allVariants.map((row: IVariant | IVariantDCOV) => {
            return [
              "name" in row ? row.name : "DČOV",
              pointsFormatterPDF(
                variantEconomicPointProvider(allVariants, row, "investment")
              ),
              pointsFormatterPDF(
                variantEconomicPointProvider(allVariants, row, "min")
              ),
              pointsFormatterPDF(
                variantEconomicPointProvider(allVariants, row, "max")
              ),
              pointsFormatterPDF(variantLandPointProvider(allVariants, row)),
              pointsFormatterPDF(variantPublicGoodPointProvider(row)),
              pointsFormatterPDF(variantPointProvider(allVariants, row)),
            ];
          }),
        ],
      },
    },
  ];
};

export default getSummary;
