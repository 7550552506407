import { pointsFormatterPDF } from "../../components/Points";
import { IVariant, IVariantDCOV, ISemaphore } from "../../interfaces";
import { landPointProvider } from "../../providers/landProvider";
import { getItemMargin, getOstr, getTocItem, IMAGE_WIDTH } from "../utils";

const getSemaphoreText = (sem: ISemaphore) => {
  switch (sem) {
    case "":
      return "";
    case "red":
      return "Červená";
    case "orange":
      return "Oranžová";
    case "green":
      return "Zelená";
  }
};

const getLand = (variant: IVariant | IVariantDCOV) => {
  // Only include lands that have some information
  const filtered = variant.land.filter(
    (l) =>
      l.name !== "" ||
      l.number !== "" ||
      l.plannerText !== "" ||
      l.mayorText !== "" ||
      l.images.length > 0
  );

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    {
      text: "Majetkoprávní vyhodnocení",
      style: "h3",
      pageBreak: "before",
      ...getTocItem(),
    },
    ...filtered.map((l) => {
      const content = [];

      if (l.images.length > 0) {
        content.push(
          {
            text: "Ilustrační obrázky",
            style: "h4",
          },
          ...l.images.map((image) => ({
            image,
            width: IMAGE_WIDTH,
            ...getItemMargin(),
          }))
        );
      }

      content.push({
        layout: "lightHorizontalLines",
        table: {
          body: [
            [
              { text: "Název katastru", style: "tableHeader" },
              { text: "Parcelní číslo", style: "tableHeader" },
              { text: "Vstupní semafor", style: "tableHeader" },
              { text: "Výstupní semafor", style: "tableHeader" },
              { text: "Klíčový pozemek", style: "tableHeader" },
              { text: "Body", style: "tableHeader" },
            ],
            [
              getOstr(l.name),
              getOstr(l.number),
              getSemaphoreText(l.inSemaphore),
              getSemaphoreText(l.outSemaphore),
              getOstr(l.keyParcel ? "Ano" : "Ne"),
              pointsFormatterPDF(landPointProvider(l)),
            ],
          ],
        },
        margin: [0, 0, 0, 5],
      });

      if (l.plannerText !== "") {
        content.push(
          {
            text: "Zápis projektanta:",
            bold: true,
          },
          {
            text: l.plannerText,
          }
        );
      }

      if (l.mayorText !== "") {
        content.push(
          {
            text: "Zápis starosty:",
            bold: true,
          },
          {
            text: l.mayorText,
          }
        );
      }

      return {
        stack: content,
        unbreakable: true,
        ...getItemMargin(),
      };
    }),
  ];
};

export default getLand;
