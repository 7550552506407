import { IVariant, IVariantDCOV } from "../../interfaces";
import { sanaceReconstructionBigProvider } from "../../providers/sanaceReconstructionBigProvider";
import { currencyFormatter } from "../../utils";
import { getPrice } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getSanaceReconstructionBig = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.sanaceReconstruction.filter((g) => g.quantity > 0);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    {
      text: "Rekonstrukce kanalizace - bourání a následná obnova stropu šachet u profilu DN 800 a vyšších",
      style: "h4",
    },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: ["auto", "auto", "auto", "*"],
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Cena za 1ks (bez DPH)", style: "tableHeader" },
            { text: "Počet", style: "tableHeader" },
            { text: "Cena", style: "tableHeader" },
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            currencyFormatter(g.price),
            g.quantity,
            getPrice(g, sanaceReconstructionBigProvider)[1],
          ]),
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getSanaceReconstructionBig;
