import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Card,
} from "@mui/material";

interface IProps {
  unsavedChanges: boolean;
  onDrop: (data: object) => void;
}

export default function ProjectDropzone(props: IProps) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const contents = event.target!.result;
        const data = JSON.parse(contents as string);
        if (props.unsavedChanges) {
          setFileData(data);
          setDialogOpen(true);
        } else {
          props.onDrop(data);
        }
      };
      reader.readAsText(acceptedFiles[0]);
    },
    [props]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "application/json",
  });
  const [fileData, setFileData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="alert-dialog-title">Přepsat projekt?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Máte neuložené změny, opravdu chcete přepsat aktuální projekt?
            Všechny data z tohoto projektu budou ztraceny.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
              props.onDrop(fileData);
            }}
          >
            Potvrdit
          </Button>
          <Button autoFocus onClick={() => setDialogOpen(false)}>
            Zrušit
          </Button>
        </DialogActions>
      </Dialog>
      <Card {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>
            Přetáhněte sem konfigurační soubor s projektem, nebo klikněte pro
            výběr
          </p>
        )}
      </Card>
    </>
  );
}
