import { IVariant } from "../interfaces";
import { covDefaultState } from "../providers/covProvider";
import { gravitationalConnectionDefaultState } from "../providers/gravitationalConnectionProvider";
import { gravitationalDefaultState } from "../providers/gravitationalProvider";
import { pressuredDefaultState } from "../providers/pressuredProvider";
import { seepageDefaultState } from "../providers/seepageProvider";
import { storageTankDefaultState } from "../providers/storageTankProvider";
import { landDefaultState } from "../providers/landProvider";
import { publicGoodDefaultState } from "../providers/publicGoodProvider";
import { sanaceDefaultState } from "../providers/sanaceProvider";
import { sanaceConnectionDefaultState } from "../providers/sanaceConnectionProvider";
import { sanaceReconstructionDefaultState } from "../providers/sanaceReconstructionProvider";
import { sanaceReconstructionBigDefaultState } from "../providers/sanaceReconstructionBigProvider";
import { detailedGeoDefaultState } from "../providers/detailedGeoProvider";
import { indicativeGeoDefaultState } from "../providers/indicativeGeoProvider";
import { nonTableDefaultState } from "../providers/nonTableProvider";
import { pressuredStationDefaultState } from "../providers/pressuredStationProvider";

export default {
  id: 0,
  name: "Bez názvu",
  gravitational: [gravitationalDefaultState],
  pressured: [pressuredDefaultState],
  gravitationalConnection: [gravitationalConnectionDefaultState],
  pressuredStation: [pressuredStationDefaultState],
  detailedGeo: detailedGeoDefaultState,
  indicativeGeo: indicativeGeoDefaultState,
  cov: [covDefaultState],
  storageTank: [storageTankDefaultState],
  seepage: [seepageDefaultState],
  centralExpanded: true,
  decentralExpanded: true,
  reconstructionExpanded: false,
  nonTableExpanded: false,
  land: [landDefaultState],
  publicGood: publicGoodDefaultState,
  sanace: [sanaceDefaultState],
  sanaceConnection: [sanaceConnectionDefaultState],
  sanaceReconstruction: [sanaceReconstructionDefaultState],
  sanaceReconstructionBig: [sanaceReconstructionBigDefaultState],
  sanaceRoad: 0,
  nonTable: [nonTableDefaultState],
  images: [],
} as IVariant;
