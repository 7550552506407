import { IVariantDCOV } from "../interfaces";
import { covDefaultState } from "../providers/covProvider";
import { detailedGeoDefaultState } from "../providers/detailedGeoProvider";
import { gravitationalConnectionDefaultState } from "../providers/gravitationalConnectionProvider";
import { indicativeGeoDefaultState } from "../providers/indicativeGeoProvider";
import { landDefaultState } from "../providers/landProvider";
import { nonTableDefaultState } from "../providers/nonTableProvider";
import { publicGoodDefaultState } from "../providers/publicGoodProvider";
import { sanaceConnectionDefaultState } from "../providers/sanaceConnectionProvider";
import { sanaceDefaultState } from "../providers/sanaceProvider";
import { sanaceReconstructionBigDefaultState } from "../providers/sanaceReconstructionBigProvider";
import { sanaceReconstructionDefaultState } from "../providers/sanaceReconstructionProvider";
import { seepageDefaultState } from "../providers/seepageProvider";
import { storageTankDefaultState } from "../providers/storageTankProvider";

export default {
  gravitationalConnection: [gravitationalConnectionDefaultState],
  detailedGeo: detailedGeoDefaultState,
  indicativeGeo: indicativeGeoDefaultState,
  cov: [covDefaultState],
  storageTank: [storageTankDefaultState],
  seepage: [seepageDefaultState],
  land: [landDefaultState],
  publicGood: publicGoodDefaultState,
  sanace: [sanaceDefaultState],
  sanaceConnection: [sanaceConnectionDefaultState],
  sanaceReconstruction: [sanaceReconstructionDefaultState],
  sanaceReconstructionBig: [sanaceReconstructionBigDefaultState],
  sanaceRoad: 0,
  nonTable: [nonTableDefaultState],
  images: [],
  reconstructionExpanded: false,
  nonTableExpanded: false,
} as IVariantDCOV;
