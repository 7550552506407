import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Price from "../components/Price";

import { ISewerage } from "../interfaces";
import {
  gravitationalOptionProvider,
  gravitationalPriceProvider,
} from "../providers/gravitationalProvider";

interface IProps {
  state: ISewerage;
  setState: (state: any) => void;
}

export default function ItemGravitational(props: IProps) {
  const { types, profiles } = gravitationalOptionProvider(props.state);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel>Materiál</InputLabel>
          <Select
            label="Materiál"
            value={props.state.type}
            size="small"
            onChange={(e) =>
              props.setState({ ...props.state, type: e.target.value })
            }
          >
            {types.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Profil</InputLabel>
          <Select
            label="Profil"
            size="small"
            value={props.state.profile}
            onChange={(e) => {
              props.setState({ ...props.state, profile: e.target.value });
            }}
          >
            {profiles.map((profile) => (
              <MenuItem key={profile} value={profile}>
                {profile}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            label="Délka"
            size="small"
            value={props.state.quantity}
            onChange={(e) =>
              props.setState({ ...props.state, quantity: e.target.value })
            }
            type="number"
            inputProps={{ min: 0 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.state.consolidated}
              onChange={(e) =>
                props.setState({
                  ...props.state,
                  consolidated: e.target.checked,
                })
              }
            />
          }
          label="Zpevněné plochy"
        />
      </Grid>
      <Grid item xs={2}>
        <Price data={props.state} provider={gravitationalPriceProvider} />
      </Grid>
    </Grid>
  );
}
