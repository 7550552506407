import { ICalculation } from "../interfaces";
import { currencyFormatter } from "../utils";

export const getPriceHeader = () => [
  { text: "" },
  { text: "Cena", style: "tableHeader" },
];

export const getWidths = (numColumns: number) => {
  const widths = new Array(numColumns).fill("auto"); // Existing columns
  widths.push("*"); // Space between existing columns and price
  widths.push("auto"); // Price
  return widths;
};

export const getPrice = (
  data: any,
  provider: (data: any, method: string) => ICalculation
) => {
  const costMMR = provider(data, "mmr");
  const costMZe = provider(data, "mze");

  let buffer = "";

  // MMR
  if (costMMR.genuine) {
    buffer += "MMR\n";
    buffer += `- Investice ${currencyFormatter(costMMR.investment)}\n`;
    buffer += `- Náklady min. ${currencyFormatter(costMMR.min)}\n`;
    buffer += `- Náklady max. ${currencyFormatter(costMMR.min)}\n`;
  }

  // MZe
  if (costMZe.genuine) {
    buffer += "MZe\n";
    buffer += `- Investice ${currencyFormatter(costMZe.investment)}\n`;
    buffer += `- Náklady min. ${currencyFormatter(costMZe.min)}\n`;
    buffer += `- Náklady max. ${currencyFormatter(costMZe.min)}\n`;
  }

  return ["", buffer];
};
