import { IVariant } from "../../interfaces";
import { gravitationalPriceProvider } from "../../providers/gravitationalProvider";
import { getPrice } from "../price";
import { getItemMargin, getOstr } from "../utils";

const getGravitational = (variant: IVariant) => {
  const filtered = variant.gravitational.filter((g) => g.quantity > 0);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    { text: "Kanalizace gravitační", style: "h4" },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: ["auto", "auto", "auto", "auto", "auto", "*"],
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Materiál", style: "tableHeader" },
            { text: "Profil", style: "tableHeader" },
            { text: "Délka", style: "tableHeader" },
            { text: "Zpevněné plochy", style: "tableHeader" },
            { text: "Cena", style: "tableHeader" },
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            g.type,
            g.profile,
            g.quantity,
            g.consolidated ? "Ano" : "Ne",
            getPrice(g, gravitationalPriceProvider)[1],
          ]),
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getGravitational;
