import { INonTable } from "../interfaces";
import { calculateCosts, includeVat } from "../utils";

export function nonTablePriceProvider(data: INonTable, method: string) {
  return calculateCosts(
    includeVat(Number(data.price), !data.withVAT),
    { min: data.min, max: data.max },
    true
  );
}

export const nonTableDefaultState: INonTable = {
  text: "",
  price: 0,
  withVAT: true,
  min: 50,
  max: 100,
};
