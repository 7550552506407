import {
  IVariant,
  IVariantDCOV,
} from "../interfaces";
import ItemStack from "../items/ItemStack";
import ItemGravitationalConnection from "../items/ItemGravitationalConnection";
import {
  Typography,
  Grid,
  Box,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  FormControl,
  IconButton,
} from "@mui/material";
import ItemCOV from "../items/ItemCOV";
import Section from "../components/Section";
import ImageDropzone from "../components/ImageDropzone";
import ItemSeepageObject from "../items/ItemSeepageObject";
import { gravitationalConnectionDefaultState } from "../providers/gravitationalConnectionProvider";
import { seepageDefaultState } from "../providers/seepageProvider";
import { covDefaultState } from "../providers/covProvider";
import ItemIndicativeGeo from "../items/ItemIndicativeGeo";
import ItemDetailedGeo from "../items/itemDetailedGeo";
import ItemDivider from "../components/ItemDivider";
import ItemStorageTank from "../items/ItemStorageTank";
import { storageTankDefaultState } from "../providers/storageTankProvider";
import ItemLand from "../items/ItemLand";
import ItemPublicGood from "../items/ItemPublicGood";
import { landDefaultState } from "../providers/landProvider";
import ItemEconomic from "../items/ItemEconomic";
import ItemSanace from "../items/ItemSanace";
import ItemSanaceConnection from "../items/ItemSanaceConnection";
import ItemSanaceReconstruction from "../items/ItemSanaceReconstruction";
import ItemSanaceReconstructionBig from "../items/ItemSanaceReconstructionBig";
import ItemSanaceRoad from "../items/ItemSanaceRoad";
import { sanaceDefaultState } from "../providers/sanaceProvider";
import { sanaceConnectionDefaultState } from "../providers/sanaceConnectionProvider";
import { sanaceReconstructionDefaultState } from "../providers/sanaceReconstructionProvider";
import { sanaceReconstructionBigDefaultState } from "../providers/sanaceReconstructionBigProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ItemNonTable from "../items/ItemNonTable";
import { nonTableDefaultState } from "../providers/nonTableProvider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WandIcon from "@mui/icons-material/AutoFixHigh";
import MagicDialog from "../components/MagicDialog";
import { useState } from "react";

interface IProps {
  data: IVariantDCOV;
  setData: (data: IVariantDCOV) => void;
  allVariants: (IVariant | IVariantDCOV)[];
  onDuplicate: () => void;
  onSend: (item: any, key: string) => void;
}

export default function DCOVVariant({
  data,
  setData,
  allVariants,
  onDuplicate,
  onSend,
}: IProps) {
  const [magicDialogOpen, setMagicDialogOpen] = useState<boolean>(false);

  return (
    <div>
      <Section bottomPadding>
        <FormControl>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() => onDuplicate()}
          >
            Duplikovat variantu
          </Button>
        </FormControl>
      </Section>
      <Section>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2">Ilustrační obrázky</Typography>
        </Box>
        <ImageDropzone
          images={data.images}
          setImages={(images) => setData({ ...data, images })}
        />
      </Section>
      <Section>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle2">ČOV domovní</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setMagicDialogOpen(true)}>
              <WandIcon />
            </IconButton>
          </Grid>
        </Grid>
        <ItemStack
          Component={ItemCOV}
          state={data.cov}
          update={(newData) => {
            setData({
              ...data,
              cov: newData,
            });
          }}
          newItemData={covDefaultState}
          sendButton
          onSend={(data) => onSend(data, "cov")}
        />
        <ItemDivider />
        <Typography variant="subtitle2">
          Kanalizace - gravitační přípojky
        </Typography>
        <ItemStack
          Component={ItemGravitationalConnection}
          state={data.gravitationalConnection}
          update={(newData) => {
            setData({
              ...data,
              gravitationalConnection: newData,
            });
          }}
          newItemData={gravitationalConnectionDefaultState}
          sendButton
          onSend={(data) => onSend(data, "gravitationalConnection")}
        />
        <ItemDivider />
        <Typography variant="subtitle2">Akumulační nádrž</Typography>
        <ItemStack
          Component={ItemStorageTank}
          state={data.storageTank}
          update={(newData) => {
            setData({
              ...data,
              storageTank: newData,
            });
          }}
          newItemData={storageTankDefaultState}
          sendButton
          onSend={(data) => onSend(data, "storageTank")}
        />
        <ItemDivider />
        <Typography variant="subtitle2">
          Bodový vsakovací objekt za ČOV
        </Typography>
        <ItemStack
          Component={ItemSeepageObject}
          state={data.seepage}
          update={(newData) => {
            setData({
              ...data,
              seepage: newData,
            });
          }}
          newItemData={seepageDefaultState}
          sendButton
          onSend={(data) => onSend(data, "seepage")}
        />
        <ItemDivider />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs>
            <Typography variant="subtitle2">
              Orientační hydrogeologický průzkum (pro plošnou zálivku)
            </Typography>
            <ItemIndicativeGeo
              state={data.indicativeGeo}
              setState={(value) =>
                setData({
                  ...data,
                  indicativeGeo: value,
                })
              }
            />
          </Grid>
          <ItemDivider
            flexItem
            orientation="vertical"
            style={{ marginLeft: 16 }}
          />
          <Grid item xs>
            <Typography variant="subtitle2">
              Podrobný hydrogeologický průzkum (pro vsakovací objekt)
            </Typography>
            <ItemDetailedGeo
              state={data.detailedGeo}
              setState={(value) =>
                setData({
                  ...data,
                  detailedGeo: value,
                })
              }
            />
          </Grid>
        </Grid>
      </Section>
      <Card sx={{ mb: 2 }}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={data.reconstructionExpanded}
          onChange={(_, expanded) =>
            setData({
              ...data,
              reconstructionExpanded: expanded,
            })
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Rekonstrukce kanalizace</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle2">Rekonstrukce kanalizace</Typography>
            <ItemStack
              Component={ItemSanace}
              state={data.sanace}
              update={(newData) => {
                setData({
                  ...data,
                  sanace: newData,
                });
              }}
              newItemData={sanaceDefaultState}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Rekonstrukce kanalizace - napojení domovních přípojek
            </Typography>
            <ItemStack
              Component={ItemSanaceConnection}
              state={data.sanaceConnection}
              update={(newData) => {
                setData({
                  ...data,
                  sanaceConnection: newData,
                });
              }}
              newItemData={sanaceConnectionDefaultState}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Rekonstrukce kanalizace - rekonstrukce kanalizačních šachet
            </Typography>
            <ItemStack
              Component={ItemSanaceReconstruction}
              state={data.sanaceReconstruction}
              update={(newData) => {
                setData({
                  ...data,
                  sanaceReconstruction: newData,
                });
              }}
              newItemData={sanaceReconstructionDefaultState}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Rekonstrukce kanalizace - bourání a následná obnova stropu šachet
              u profilu DN 800 a vyšších
            </Typography>
            <ItemStack
              Component={ItemSanaceReconstructionBig}
              state={data.sanaceReconstructionBig}
              update={(newData) => {
                setData({
                  ...data,
                  sanaceReconstructionBig: newData,
                });
              }}
              newItemData={sanaceReconstructionBigDefaultState}
            />
            <ItemDivider />
            <Typography variant="subtitle2">
              Rekonstrukce kanalizace - oprava asfaltové komunikace, poškozené
              při rekonstrukci kanalizace
            </Typography>
            <ItemSanaceRoad
              state={data.sanaceRoad}
              setState={(value) => {
                setData({
                  ...data,
                  sanaceRoad: value,
                });
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
      <Card>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={data.nonTableExpanded}
          onChange={(_, expanded) =>
            setData({
              ...data,
              nonTableExpanded: expanded,
            })
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Beztabulkové položky</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ItemStack
              Component={ItemNonTable}
              state={data.nonTable}
              update={(newData) => {
                setData({
                  ...data,
                  nonTable: newData,
                });
              }}
              newItemData={nonTableDefaultState}
              noName
            />
          </AccordionDetails>
        </Accordion>
      </Card>
      <Section>
        <Typography variant="subtitle2">
          Vyhodnocení ekonomické nákladnosti
        </Typography>
        <ItemEconomic variant={data} variants={allVariants} />
      </Section>
      <Section>
        <Typography variant="subtitle2">Majetkoprávní vyhodnocení</Typography>
        <ItemStack
          Component={ItemLand}
          state={data.land}
          update={(newData) => {
            setData({
              ...data,
              land: newData,
            });
          }}
          newItemData={landDefaultState}
          noName
          thickDivider
          sendButton
          onSend={(data) => onSend(data, "land")}
        />
      </Section>
      <Section bottomPadding>
        <Typography variant="subtitle2">
          Vyhodnocení veřejné prospěšnosti stavby
        </Typography>
        <ItemPublicGood
          data={data.publicGood}
          setData={(newData) =>
            setData({
              ...data,
              publicGood: newData,
            })
          }
        />
      </Section>
      <MagicDialog
        open={magicDialogOpen}
        onClose={() => setMagicDialogOpen(false)}
        data={data}
        setData={setData}
      />
    </div>
  );
}
