import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function Header() {
  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={4} wrap="nowrap" alignItems={"center"}>
        <Grid item>
          <img src="img/FIT_barevne_small.png" alt="VUT FIT logo" />
        </Grid>
        <Grid item>
          <Typography>
            <b>Upozornění</b>: Současná verze kalkulačky je ve fázi návrhu.
            Fakulta informačních technologií VUT v Brně nenese žádnou
            zodpovědnost za důsledky způsobené využitím dat poskytnutých touto
            aplikací.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
