import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import { set } from "../slices/vatSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IStore } from "../store";

interface IProps {
  onUpdate: () => void;
}

export default function VATSwitch({ onUpdate }: IProps) {
  const dispatch = useDispatch();
  const vat = useSelector((state: IStore) => state.vat);

  return (
    <FormControlLabel
      control={
        <Switch
          onChange={(e) => {
            dispatch(set(e.target.checked));
            onUpdate();
          }}
          checked={vat}
        />
      }
      label="Včetně DPH"
    />
  );
}
