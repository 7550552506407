import data from "../data/points/land.json";
import { ILand } from "../interfaces";

export function landPointProvider(land: ILand): number {
  let points = 0;
  let semaphore;

  if (land.outSemaphore === "") {
    // Use in semaphore points (planner)
    points += land.inSemaphore ? data.in_semaphore[land.inSemaphore] : 0;
    semaphore = land.inSemaphore;
  } else {
    // Use out semaphore points (mayor)
    points += data.out_semaphore[land.outSemaphore];
    semaphore = land.outSemaphore;
  }

  // Key parcel points
  // Only applies if effective semaphore is red
  if (semaphore === "red") {
    points += land.keyParcel ? data.key_parcel : 0;
  }

  return points;
}

export const landDefaultState: ILand = {
  inSemaphore: "",
  outSemaphore: "",
  keyParcel: false,
  plannerText: "",
  mayorText: "",
  name: "",
  number: "",
  images: [],
};
