import { IVariant, IVariantDCOV } from "../../interfaces";
import { detailedGeoPriceProvider } from "../../providers/detailedGeoProvider";
import { getPrice, getWidths, getPriceHeader } from "../price";
import { getItemMargin } from "../utils";

const getDetailedGeo = (variant: IVariant | IVariantDCOV) => {
  // Skip
  if (variant.detailedGeo.quantity === 0) {
    return [];
  }

  return [
    {
      stack: [
        {
          text: "Podrobný hydrogeologický průzkum (pro vsakovací objekt)",
          style: "h4",
        },
        {
          layout: "lightHorizontalLines",
          table: {
            widths: getWidths(2),
            body: [
              [
                { text: "Počet", style: "tableHeader" },
                { text: "Vysvětlivka", style: "tableHeader" },
                ...getPriceHeader(),
              ],
              [
                variant.detailedGeo.quantity.toString(),
                variant.detailedGeo.text,
                ...getPrice(variant.detailedGeo, detailedGeoPriceProvider),
              ],
            ],
          },
        },
      ],
      unbreakable: true,
      ...getItemMargin(),
    },
  ];
};

export default getDetailedGeo;
