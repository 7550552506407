import { IVariant, IVariantDCOV } from "../../interfaces";
import { getItemMargin, getOstr } from "../utils";
import { getPrice, getWidths, getPriceHeader } from "../price";
import { storageTankPriceProvider } from "../../providers/storageTankProvider";

const getStorageTank = (variant: IVariant | IVariantDCOV) => {
  const filtered = variant.storageTank.filter((g) => g.quantity > 0);

  // Skip
  if (filtered.length === 0) {
    return [];
  }

  return [
    { text: "Akumulační nádrž", style: "h4" },
    {
      layout: "lightHorizontalLines",
      table: {
        widths: getWidths(3),
        body: [
          [
            { text: "Název větve", style: "tableHeader" },
            { text: "Objem (m3)", style: "tableHeader" },
            { text: "Počet", style: "tableHeader" },
            ...getPriceHeader(),
          ],
          ...filtered.map((g) => [
            getOstr(g.name),
            g.size,
            g.quantity,
            ...getPrice(g, storageTankPriceProvider),
          ]),
        ],
      },
      ...getItemMargin(),
    },
  ];
};

export default getStorageTank;
