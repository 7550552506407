import calculate from "../Calculate";
import { IVariant, IVariantDCOV } from "../interfaces";
import Chart from "./Chart";
import CostsTable from "./CostsTable";

interface IProps {
  variant: IVariant;
  dcov: IVariantDCOV;
}

export default function CostsResults({ variant, dcov }: IProps) {
  const data = calculate(variant, dcov);

  return (
    <>
      <Chart data={data} />
      <CostsTable data={data} />
    </>
  );
}
