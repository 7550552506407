import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import { IVariant, IVariantDCOV } from "../interfaces";

interface IProps {
  open: boolean;
  variants: (IVariant | IVariantDCOV)[];
  itemKey?: string;
  onClose: () => void;
  onSend: (key: number) => void;
}

export default function SendDialog({
  open,
  variants,
  itemKey,
  onClose,
  onSend,
}: IProps) {
  const [variant, setVariant] = useState<number>(0);

  useEffect(() => {
    // Find first suitable variant
    for (let i = 0; i < variants.length; i++) {
      if (typeof itemKey === "undefined" || itemKey in variants[i]) {
        setVariant(i);
        break;
      }
    }
  }, [itemKey, variants]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Kopírování položky do varianty</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Vyberte variantu, do které chcete položku zkopírovat.
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel>Varianta</InputLabel>
          <Select
            label="Varianta"
            value={variant}
            onChange={(e) => setVariant(Number(e.target.value))}
          >
            {variants.map((variant, index) => {
              return typeof itemKey === "undefined" || itemKey in variant ? (
                <MenuItem key={index} value={index}>
                  {"name" in variant ? variant.name : "DČOV"}
                </MenuItem>
              ) : null;
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Zrušit</Button>
        <Button onClick={() => onSend(variant)}>Zkopírovat</Button>
      </DialogActions>
    </Dialog>
  );
}
