import { useTheme } from "@mui/material/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { currencyFormatter } from "../utils";
import { ImplicitLabelType } from "recharts/types/component/Label";
import { IChartData } from "../interfaces";

interface IProps {
  data: IChartData[];
  width?: number;
  height?: number;
  forRender?: boolean;
}

// Get label size based on data (maximum price)
const getFontSize = (data: IChartData[]) => {
  // Get maximum price across all columns
  const maxPrice = Math.max(
    ...data.map((item) => Math.max(item.investment, item.min, item.max))
  );

  if (maxPrice < 100000) return 15;
  if (maxPrice < 1000000) return 13;
  if (maxPrice < 10000000) return 12;
  if (maxPrice < 100000000) return 11;
  return 10;
};

export default function Chart({ data, forRender, width, height }: IProps) {
  const theme = useTheme();

  const textColor = forRender ? "#000000" : theme.palette.text.primary;
  const lineColor = forRender ? "#eeeeee" : theme.palette.divider;

  const label: ImplicitLabelType = {
    fill: textColor,
    width: 50,
    fontSize: getFontSize(data),
    position: "top",
    formatter: currencyFormatter,
  };

  const chartProps = forRender
    ? {
        width,
        height,
      }
    : {};

  const barProps = forRender
    ? {
        label,
        isAnimationActive: false, // Disable animation for rendering, because graphs wouldn't be visible
      }
    : { label };

  const chart = (
    <BarChart
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      {...chartProps}
    >
      <CartesianGrid strokeDasharray="3 3" stroke={lineColor} />
      <XAxis
        dataKey="name"
        tick={{ fill: textColor }}
        tickLine={{ fill: lineColor }}
        axisLine={{ fill: lineColor }}
      />
      <YAxis
        tickFormatter={currencyFormatter}
        width={100}
        tick={{ fill: textColor }}
        tickLine={{ fill: lineColor }}
        axisLine={{ fill: lineColor }}
      />
      <Legend />
      <Bar
        dataKey="investment"
        name="Náklady investiční Kč"
        fill={"#ef7421"}
        {...barProps}
      />
      <Bar
        dataKey="min"
        name="Náklady po dobu provozování min. (za 100 let provozu)"
        fill="#9d9d9d"
        {...barProps}
      />
      <Bar
        dataKey="max"
        name="Náklady po dobu provozování max. (za 100 let provozu)"
        fill="#ffc600"
        {...barProps}
      />
    </BarChart>
  );

  if (forRender) {
    return chart;
  }

  return <ResponsiveContainer height={300}>{chart}</ResponsiveContainer>;
}
