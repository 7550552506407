import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { IChartData } from "../interfaces";
import { currencyFormatter } from "../utils";

interface IProps {
  data: IChartData[];
}

export default function CostsTable({ data }: IProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Varianta</TableCell>
            <TableCell>Náklady investiční</TableCell>
            <TableCell>
              Náklady po dobu provozování min. (za 100 let provozu)
            </TableCell>
            <TableCell>
              Náklady po dobu provozování max. (za 100 let provozu)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{currencyFormatter(row.investment)}</TableCell>
              <TableCell>{currencyFormatter(row.min)}</TableCell>
              <TableCell>{currencyFormatter(row.max)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
