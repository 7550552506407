import { IVariant, IVariantDCOV } from "../interfaces";
import getCov from "./items/cov";
import getDetailedGeo from "./items/detailedGeo";
import getEconomic from "./items/economic";
import getGravitationalConnection from "./items/gravitationalConnection";
import getIndicativeGeo from "./items/indicativeGeo";
import getLand from "./items/land";
import getNonTable from "./items/nonTable";
import getPublicGood from "./items/publicGood";
import getSanace from "./items/sanace";
import getSanaceConnection from "./items/sanaceConnection";
import getSanaceReconstruction from "./items/sanaceReconstruction";
import getSanaceReconstructionBig from "./items/sanaceReconstructionBig";
import getSanaceRoad from "./items/sanaceRoad";
import getSeepage from "./items/seepage";
import getStorageTank from "./items/storageTank";
import { getSectionMargin, getTocItem, IMAGE_WIDTH } from "./utils";

const getItems = (dcov: IVariantDCOV) => {
  return [
    ...getCov(dcov),
    ...getStorageTank(dcov),
    ...getGravitationalConnection(dcov),
    ...getSeepage(dcov),
    ...getIndicativeGeo(dcov),
    ...getDetailedGeo(dcov),
    ...getSanace(dcov),
    ...getSanaceConnection(dcov),
    ...getSanaceReconstruction(dcov),
    ...getSanaceReconstructionBig(dcov),
    ...getSanaceRoad(dcov),
    ...getNonTable(dcov),
  ];
};

// Get DCOV variant
const getVariantDCOV = (dcov: IVariantDCOV, variants: IVariant[]) => {
  const content = [];

  // Header
  content.push({
    text: "Varianta DČOV",
    style: "h2",
    pageBreak: "before",
    margin: [0, 0, 0, 10],
    tocItem: true,
  });

  // Variant image
  if (dcov.images.length > 0) {
    content.push({ text: "Ilustrační obrázky", style: "h4" });
    content.push(
      dcov.images.map((image) => ({
        image,
        width: IMAGE_WIDTH,
        ...getSectionMargin(),
      }))
    );
  }

  // Items
  const items = getItems(dcov);
  if (items.length > 0) {
    content.push({ text: "Položky", style: "h3", ...getTocItem() });
    content.push(...items);
  }

  content.push(...getEconomic([dcov, ...variants], dcov));

  // Land
  content.push(...getLand(dcov));

  // Public good
  content.push(...getPublicGood(dcov));

  return content;
};

export default getVariantDCOV;
